<template>
  <div class="grad faleconosco mb-2">
    <div
      class="gradient-enter"
      :style="{background: 'linear-gradient(225deg, '+colorPrimary+' 0%, '+colorSecondary+' 100%)'}"
    ></div>
    <div
      class="gradient-enter"
      style="background-image:url('https://www.10wallpaper.com/wallpaper/1366x768/1710/Geometric_material_design-2017_High_Quality_Wallpaper_1366x768.jpg') !important; filter:grayscale(1); opacity:.15"
    ></div>
    <div class="texto text-center">
      <div class="container-fluid mx-auto contact-us-box">
        <h3 class="text-white mb-0">Gostaria de saber mais sobre o projeto?</h3>
        <h2 class="font-destaque text-white mt-0">Fale conosco!</h2>
        <form>
          <address class="title-contact-lp color-theme-texto">
            <v-icon class="mr-2 text-info v-middle-adjust" size="17">fas fa-map-marker-alt</v-icon><a :href="getAddress(true)" target="_blank" class="text-info decor-none">{{ getAddress(false) }}</a>
            <!-- <br />Telefone: (011) 5818-0878 -->
          </address>
          <div>
              <v-container form-grid>
                <v-layout row wrap>
                    <v-flex xs12 sm4>
                        <!-- <label for class="text-left">Nome</label> -->
                        <InputText
                        ref="nameInput"
                        type="text"
                        place-holder="Informe seu nome completo"
                        textLabel="Nome"
                        invalidInputMessage="Informe seu nome completo"
                        v-model="contact.name"
                        />
                        <!-- <input type="text" placeholder="Informe seu nome completo" /> -->
                    </v-flex>
                    <v-flex xs12 sm4>
                        <!-- <label for class="text-left">E-mail</label> -->
                        <InputText
                        ref="emailInput"
                        type="email"
                        place-holder="Digite seu e-mail"
                        textLabel="E-mail"
                        invalidInputMessage="Por favor informe um e-mail válido"
                        v-model="contact.email"
                        :validation="validateEmail"
                        />
                        <!-- <input type="text" placeholder="Informe seu endereço de e-mail" /> -->
                    </v-flex>
                    <v-flex xs12 sm4>
                        <InputText type="tel"
                                place-holder="Informe o telefone do contato"
                                textLabel="Telefone"
                                :mask="['(##) ####-####', '(##) #####-####']"
                                v-model="contact.phone"
                                :validation="validateCellPhone"
                                invalidInputMessage="Preencha o telefone" /> 
                    </v-flex>
                    <v-flex xs12>
                        <!-- <label for class="text-left">Mensagem</label> -->
                        <InputTextArea ref="messageInput"
                                    type="text"
                                    rows="7"
                                    place-holder="Escreva a sua mensagem"
                                    textLabel="Mensagem"
                                    invalidInputMessage="Por favor preencha o assunto"
                                    v-model="contact.message" />
                        <!-- <textarea name id cols="30" rows="10"></textarea> -->
                    </v-flex>
                </v-layout>
                <v-btn
                class="btn-primary px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text mt-4 bg-theme-primaria"
                @click="send"
                >ENVIAR</v-btn>
              </v-container>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="700px">
      <template v-slot:activator="{ on }">
        <a v-on="on">
          <Icon :name="'uil uil-envelope'" :size="24" />
        </a>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>
            <div>Fale Conosco</div>
          </h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text column>
          <v-layout row wrap w-100 list-scroll pa-4>
            <v-flex xs12>
              <InputText
                ref="nameInput"
                type="text"
                place-holder="Informe seu nome completo"
                textLabel="Nome"
                invalidInputMessage="Informe seu nome completo"
                v-model="contact.name"
              />
            </v-flex>
            <v-flex xs12>
              <InputText
                ref="emailInput"
                type="email"
                place-holder="Digite seu e-mail"
                textLabel="E-mail"
                invalidInputMessage="Por favor informe um e-mail válido"
                v-model="contact.email"
                :validation="emailValidation"
              />
            </v-flex>
            <v-flex xs12>
              <InputText
                ref="subjectInput"
                type="text"
                place-holder="Escreva o assunto da sua mensagem"
                textLabel="Assunto"
                invalidInputMessage="Por favor preencha o assunto"
                v-model="contact.subject"
              />
            </v-flex>
            <v-flex xs12 mt-2>
              <InputTextArea
                ref="messageInput"
                type="text"
                rows="4"
                place-holder="Escreva a sua mensagem"
                textLabel="Mensagem"
                invalidInputMessage="Por favor preencha o assunto"
                v-model="contact.message"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout row wrap w-100 px-4 py-3>
            <v-flex xs12 text-xs-right>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 ma-0"
                large
                @click="send"
              >Enviar</v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>-->
</template>

<script type="plain/text">
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import Contact from "@/scripts/models/contact.model";
import ProjectService from "@/scripts/services/project.service.js";
import CommonHelper from "@/scripts/helpers/common.helper";
export default {
  extends: ValidatableComponent,
  props: ["institution", "colorPrimary", "colorSecondary"],
  data() {
    return {
      contact: new Contact(),
      projectService: new ProjectService()
    };
  },
  methods: {
    getAddress(isLink) {
      var result = "";

      if (
        this.institution &&
        this.institution.entity &&
        this.institution.entity.address
      ) {
        var address = this.institution.entity.address;
        if (address.street) {
          result = address.street;
          if (address.number) result += ", " + address.number;
          if (address.city) result += " - " + address.city;
          if (address.state) result += ", " + address.state;
          if (address.postalCode && !isLink) result += " / CEP " + address.postalCode;
        }
      }
      if (isLink) return 'https://www.google.com/maps?q='+result;
      else return result
    },
    send() {
      if (this.isValid()) {
        this.projectService.sendContact(this.sendCallback, this.contact);
      }
    },
    sendCallback() {
      this.contact = new Contact();
    },
    validateEmail() {
      return CommonHelper.validateEmail(this.contact.email);
    }
  }
};
</script>