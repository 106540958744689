<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-if="showButton" v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white mt-3 font-sys" large v-on="on">
          Editar notícias
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Notícias</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex md5 xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-4 mb-2"
                large
                @click="create"
				:disabled="selectedNewsId == 0"
              >
                <i class="far fa-plus mr-2"></i>Nova notícia
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal flex"
                  :class="{ 'active': selectedNewsId === news.id }"
                  @click="selectNews(news.id)"
                  v-for="news in newsList"
                  :key="news.id"
                >
                  <div class="mr-3">
                    <FileImage :file="news.file" :alt="''"/>
                    <!-- <CampaignNewsImage :news="news" :alt="''"/> -->
                  </div>
                  <div>
                    <div>{{ commonHelper.getSubstring(news.title, 80) }}</div>
                    <span v-html="commonHelper.getSubstring(news.body, 80)"></span>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex class="box-heart-father" md7 xs12 :class="{active: showForm}">
              <div class="list-scroll">
                <div
                  class="bg-news-modal"
                  id="preview"
                  ref="preview"
                  :style="'backgroundImage:' + getImage()"
                >
                  <input
                    type="file"
                    id="upload-news-image"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_institution"
                  >
                  <label for="upload-news-image">
                    <i class="fal fa-cloud-upload mr-2"></i>
                    Imagem
                  </label>
                </div>
                <div class="px-4 mt-3">
                  <InputText
                    type="text"
                    place-holder="Título da notícia"
                    textLabel="Título"
                    v-model="campaignNews.title"
                  />
                  <!-- <InputTextArea
                    place-holder="Escreva aqui a notícia"
                    textLabel="Corpo da notícia"
                    v-model="campaignNews.body"
                  ></InputTextArea> -->
                    <vue-editor
                      v-model="campaignNews.body"
                      :editor-toolbar="customToolbar"
                    />
                </div>
              </div>
              <v-flex class="align-center justify-space-between footer-step-modal">
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="openConfirmDeleteNewsModal(campaignNews.id)"
                  :disabled="selectedNewsId == 0"
                >Excluir</v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                >Salvar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir essa notícia?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedNewsId)"
            :disabled="selectedNewsId == 0"
          />
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import CampaignNews from "@/scripts/models/campaignNews.model";
import CampaignService from "@/scripts/services/campaign.service";
import FileImage from "@/components/FileImage.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    AucAlert,
    VueEditor,
    FileImage
  },
  props: {
    showButton: Boolean,
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }],['link']],
      campaignService: new CampaignService(),
      campaignNews: new CampaignNews(),
      newsList: this.value,
      selectedNewsId: null,
      showForm: false,
			commonHelper: CommonHelper,
      dialog: false
    };
  },
  computed: {
    isValid() {
      if (
        this.campaignNews &&
        this.campaignNews.title &&
        this.campaignNews.body &&
        this.campaignNews.file &&
        this.campaignNews.file.path
      )
        return true;
      return false;
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.create();
      }
    }
  },
  methods: {
    open(){
      this.dialog = true;
    },
    openConfirmDeleteNewsModal(id) {
      this.selectedNewsId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listNewsByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.newsList = data;
      this.$emit("input", data);
    },
    selectNews(newsId) {
      let news = this.newsList.find(news => news.id == newsId);
      this.selectedNewsId = newsId;
      this.copy(news);
    },
    copy(news) {
      this.campaignNews = new CampaignNews();
      this.campaignNews.id = news.id;
      this.campaignNews.campaignId = news.campaignId;
      this.campaignNews.title = news.title;
      this.campaignNews.body = news.body;
      this.campaignNews.campaignFileType = news.campaignFileType;
      this.campaignNews.file.id = news.file.id;
      this.campaignNews.file.userId = news.file.userId;
      this.campaignNews.file.name = news.file.name;
      this.campaignNews.file.path = news.file.path;
      this.campaignNews.file.size = news.file.size;
      this.campaignNews.file.extension = news.file.extension;
      this.campaignNews.file.origin = news.file.origin;
      this.campaignNews.file.publicId = news.file.publicId;
      if (news.file != null && news.file.hasOwnProperty("imageContent"))
        this.campaignNews.file.imageContent = news.file.imageContent;
    },
    create() {
      this.selectedNewsId = 0;
      this.campaignNews = new CampaignNews();
	  CommonHelper.clearInputFile(this.$refs.upload_institution);
    },
    getImage() {
      if (
        this.campaignNews == null ||
        this.campaignNews.file == null ||
        !this.campaignNews.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.campaignNews.file.path + ")";
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();
      this.campaignNews.file.name = file.name;
      reader.onload = function() {
        this.campaignNews.file.path = reader.result;
        this.campaignNews.file.imageContent = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      this.campaignNews.campaignId = this.campaignId;
      this.campaignService.saveNews(this.saveCallback, this.campaignNews);
    },
    saveCallback(data) {
      this.listByCampaignId();
      this.create();
    },
    remove(selectedNewsId) {
      this.campaignService.deleteNews(this.removeCallback, selectedNewsId);
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    }
  }
};
</script>