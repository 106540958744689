<template>
    <div>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            content-class="volunteering"
        >
            <v-card class="print-modal non-printable">
                <v-toolbar dark color="primary">
                    <v-btn @click="print">
                        <Icon name="far fa-print" size="20" class="mr-2" />Imprimir
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark text @click="$emit('onClose', false)">
                            <Icon name="fal fa-times" size="20" />
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="paper">
                    <div id="´printMeConsentTerms" v-html="consentTerms"></div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    props: {
        dialog: Boolean,
        consentTerms: String,
    },
    methods: {
        print() {

            if (document.getElementById != null) {
                var html = '<HTML>\n<HEAD>\n';
                if (document.getElementsByTagName != null) {
                    var headTags = document.getElementsByTagName("head");
                    if (headTags.length > 0) html += headTags[0].innerHTML;
                }

                html += '\n</HE' + 'AD>\n<BODY>\n';
                var printReadyElem = document.getElementById("´printMeConsentTerms");

                if (printReadyElem != null) html += printReadyElem.innerHTML;
                else {
                    alert("Error, no contents.");
                    return;
                }

                html += '\n</BO' + 'DY>\n</HT' + 'ML>';
                var printWin = window.open("", "processPrint");
                printWin.document.open();
                printWin.document.write(html);
                printWin.document.close();

                window.setTimeout(function () {
                    printWin.print();
                }, 1000);

            } else alert("Browser not supported.");

        }
    }
}

</script>