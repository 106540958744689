const Causes = {
    AssistênciaSocial: 1,
    CapacitacaoProfissional: 2,
    CulturaComunicacaoArtes: 3,
    Educacao: 4,
    Esporte: 5,
    Saude: 6,
    Animais: 7,
    MeioAmbiente: 8,
    MultiplasCausas: 12,
    DesenvolvimentoComunitario: 13,
    GeracaoRendaEmprego: 14,
    DoacaoParaCampanha: 15,
    Carnaval: 17,
    Cinema: 18,
    EventoEsportivo: 19,
    Futebol: 20,
    Música: 21,
    Patriocinio: 22,
    Teatro: 23
};

export { Causes };