import File from "@/scripts/models/file.model";
import { FileType } from "@/scripts/models/enums/fileType.enum";

export default class CampaignCase {

	constructor() {
		this.id = 0;
		this.campaignId = 0;
		this.file = new File();
		this.title = '';
		this.body = '';
		this.body2 = '';
		this.campaignFileType = FileType.ImagemCasoCampanha;
	}
}