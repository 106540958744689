<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white px-5 mt-3 font-sys" large v-on="on">
          Editar Parceiros
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Parceiros</h1>
          <!-- <div class="counter-gallery">
            <div>6</div>
            <span>/</span>
            <div>18</div>
          </div>-->
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="partner-scroll" style="max-height:600px">
          <div class="section image-grid partners">
            <div>
              <div class="wrapper">
                <div class="item" v-for="partner in partnerList" :key="partner.id">
                  <div class="tools">
                    <a @click="openConfirmDeletePartnerModal(partner.id)" class="fas fa-trash"></a>
                  </div>
                  <!-- <CampaignPartnerImage :fileId="partner.file.id" :alt="''" /> -->
                  <FileImage :file="partner.file" :alt="''"/>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <div class="upload-bar-gallery partners w-100">
            <label for="upload-partner-campaign">
              <i class="fal fa-cloud-upload"></i>
            </label>
            <input
              type="file"
              id="upload-partner-campaign"
              accept="image/jpg, image/jpeg, image/png, image/gif"
              @change="fileChanged"
              ref="upload_partner_campaign"
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AucAlert
      ref="confirmationComponent"
      title="Você tem certeza que deseja excluir esse arquivo?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removePartner(selectedPartnerId)"
    />
  </v-layout>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
// import CampaignPartnerImage from "@/components/CampaignPartnerImage.vue";
import FileImage from "@/components/FileImage.vue";
import CampaignPartner from "@/scripts/models/campaignPartner.model";
import CampaignService from "@/scripts/services/campaign.service";

export default {
  components: {
		AucAlert,
		FileImage
    // CampaignPartnerImage
  },
  props: {
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      campaignService: new CampaignService(),
      campaignPartner: new CampaignPartner(),
      partnerList: this.value,
      selectedPartnerId: 0,
      dialog: false,
      allowedTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
    };
  },
  methods: {
    openConfirmDeletePartnerModal(id) {
      this.selectedPartnerId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listPartnersByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.partnerList = data;
      this.$emit("input", data);
    },
    fileChanged(event) {
      var file = event.target.files[0];
      for (const imageType of this.allowedTypes) {
        if (file.type === imageType) {
          let reader = new FileReader();
        this.campaignPartner.file.name = file.name;
        reader.onload = function() {
          this.campaignPartner.file.path = reader.result;
          this.campaignPartner.campaignId = this.campaignId;
          return this.campaignService.savePartner(
            this.saveCallback,
            this.campaignPartner
          );
        }.bind(this);
        reader.readAsDataURL(file);
          }
      }


    },
    saveCallback() {
      this.listByCampaignId();
    },
    removePartner(partnerId) {
      this.campaignService.deletePartner(this.removeCallback, partnerId);
    },
    removeCallback() {
      this.listByCampaignId();
    }
  }
};
</script>