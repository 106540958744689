<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-if="showButton" v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white px-5 mt-3 font-sys" large v-on="on">
          Editar Galeria
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Galeria de fotos</h1>
          <!-- <div class="counter-gallery">
            <div>2</div>
            <span>/</span>
            <div>8</div>
          </div>-->
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto" class="gallery-scroll">
          <v-layout w100 mobile-r-column d-flex>
            <v-flex xs12 class="border-horizontal display-flex f-d-column">
              <div class="gallery-father">
                <div v-for="image in imageList" :key="image.id">
                  <FileImage :file="image.file" :alt="''"/>
                  <a @click="openConfirmDeleteImageModal(image.id)">
                    <i class="fas fa-trash"></i>
                  </a>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-show="!imageList || imageList.length < 10">
          <div class="upload-bar-gallery w-100">
            <label for="upload-gallery-campaign">
              <i class="fal fa-cloud-upload"></i>
            </label>
            <input
              type="file"
              id="upload-gallery-campaign"
              accept="image/*"
              @change="fileChanged"
              ref="upload_gallery_campaign"
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AucAlert
      ref="confirmationComponent"
      title="Você tem certeza que deseja excluir esse arquivo?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removeImage(selectedImageId)"
    />
  </v-layout>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import CampaignImage from "@/scripts/models/campaignImage.model";
import CampaignService from "@/scripts/services/campaign.service";

export default {
  components: {
    AucAlert,
    FileImage
  },
  props: {
    showButton: Boolean,
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      campaignService: new CampaignService(),
      campaignImage: new CampaignImage(),
      imageList: this.value,
      selectedImageId: 0,
      dialog: false
    };
  },
  methods: {
    openConfirmDeleteImageModal(id) {
      this.selectedImageId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listImagesByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.imageList = data;
      this.$emit("input", data);
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();
      this.campaignImage.file.name = file.name;
      reader.onload = function() {
        this.campaignImage.file.path = reader.result;
        this.campaignImage.campaignId = this.campaignId;
        this.campaignService.saveImage(this.saveCallback, this.campaignImage);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    saveCallback() {
      this.listByCampaignId();
    },
    removeImage(imageId) {
      this.campaignService.deleteImage(this.removeCallback, imageId);
    },
    removeCallback() {
      this.listByCampaignId();
    }
  }
};
</script>