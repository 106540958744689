<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px" :key="keyFile">
      <template v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white px-5 mt-3 font-sys" large v-on="on">
          Editar casos
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Casos</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog=false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex md5 xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-4 mb-2"
                large
                @click="create"
                :disabled="selectedCaseId == 0"
              >
                <i class="far fa-plus mr-2"></i>Novo caso
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal flex"
                  :class="{ 'active': selectedCaseId === c.id }"
                  @click="selectCase(c.id)"
                  v-for="c in caseList"
                  :key="c.id"
                >
                  <div class="mr-3">
                    <FileImage 
                    :file="c.file" 
                    :alt="''"
                    effects="c_crop,g_face:auto,z_0.4,h_300,w_300"
                    />
                  </div>
                  <div>
                    <div>{{ c.title }}</div>
                    <span v-html="c.body"></span>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex class="box-heart-father" md7 xs12 :class="{active: showForm}">
              <div class="list-scroll">
                <div
                  class="bg-news-modal"
                  id="preview"
                  ref="preview"
                  :style="'background-image:' + getImage()"
                >
                  <input
                    type="file"
                    id="upload-cases-image"
                    accept="image/*"
                    @change="fileChanged()"
                    ref="upload_image_case"
                  />
                  <label for="upload-cases-image">
                    <i class="fal fa-cloud-upload mr-2"></i>
                    Imagem
                  </label>
                </div>
                <div class="px-4 mt-3">
                  <InputText
                    type="text"
                    place-holder="Título do caso"
                    textLabel="Título"
                    v-model="campaignCase.title"
                  />
                  <!-- <InputTextArea
                    place-holder="Escreva aqui o caso"
                    textLabel="Corpo do caso"
                    v-model="campaignCase.body"
                  ></InputTextArea>-->
                  <label>Texto 1:</label>
                  <vue-editor v-model="campaignCase.body" :editor-toolbar="customToolbar" />
                  <div v-if="!campaignCase.file || !campaignCase.file.imageContent">
                    <label>Texto 2:</label>
                    <vue-editor v-model="campaignCase.body2" :editor-toolbar="customToolbar" />
                  </div>
                </div>
              </div>
              <v-flex class="align-center justify-space-between footer-step-modal">
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="openConfirmDeleteCaseModal(campaignCase.id)"
                  :disabled="selectedCaseId == 0"
                >Excluir</v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                >Salvar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse caso?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedCaseId)"
          />
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import CampaignCase from "@/scripts/models/campaignCase.model";
import File from "@/scripts/models/file.model";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    AucAlert,
    VueEditor,
    FileImage
  },
  props: {
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
      campaignService: new CampaignService(),
      campaignCase: new CampaignCase(),
      caseList: this.value,
      selectedCaseId: null,
      showForm: true,
      dialog: false,
      keyFile: 0
    };
  },
  computed: {
    isValid() {
      if (
        this.campaignCase &&
        this.campaignCase.title &&
        this.campaignCase.body
      )
        return true;
      return false;
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.create();
      }
    }
  },
  methods: {
    openConfirmDeleteCaseModal(id) {
      this.selectedCaseId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listCasesByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.caseList = data;
      this.$emit("input", data);
    },
    selectCase(caseId) {
      let campaignCase = this.caseList.find(c => c.id == caseId);
      this.selectedCaseId = caseId;
      this.copy(campaignCase);
      // console.log(campaignCase)
    },
    copy(campaignCase) {
      this.campaignCase = new CampaignCase();
      this.campaignCase.id = campaignCase.id;
      this.campaignCase.campaignId = campaignCase.campaignId;
      this.campaignCase.title = campaignCase.title;
      this.campaignCase.body = campaignCase.body;
      this.campaignCase.body2 = campaignCase.body2;
      this.campaignCase.campaignFileType = campaignCase.campaignFileType;
      if(this.campaignCase.file == null)
        this.campaignCase.file = new File();
      this.campaignCase.file.id = campaignCase.file.id;
      this.campaignCase.file.userId = campaignCase.file.userId;
      this.campaignCase.file.name = campaignCase.file.name;
      this.campaignCase.file.path = campaignCase.file.path;
      this.campaignCase.file.size = campaignCase.file.size;
      this.campaignCase.file.extension = campaignCase.file.extension;
      this.campaignCase.file.origin = campaignCase.file.origin;
      this.campaignCase.file.publicId = campaignCase.file.publicId;
      if (
        campaignCase.file != null &&
        campaignCase.file.hasOwnProperty("imageContent")
      ) {
        this.campaignCase.file.imageContent = campaignCase.file.imageContent;
      }
    },
    create() {
      this.selectedCaseId = 0;
      this.campaignCase = new CampaignCase();
      CommonHelper.clearInputFile(this.$refs.upload_image_case);
      this.keyFile = Math.random();
    },
    mounted(){
      this.keyFile = Math.random();
    },
    getImage() {
      if (
        this.campaignCase == null ||
        this.campaignCase.file == null ||
        !this.campaignCase.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.campaignCase.file.path + ")";
    },
    fileChanged() {
      var file = this.$refs.upload_image_case.files[0];
      let reader = new FileReader();
      this.campaignCase.file.name = file.name;
      reader.onload = function() {
        this.campaignCase.file.path = reader.result;
        this.$set(this.campaignCase.file, "imageContent", reader.result);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      this.campaignCase.campaignId = this.campaignId;
      this.campaignService.saveCase(this.saveCallback, this.campaignCase);
    },
    saveCallback(data) {
      this.listByCampaignId();
      this.create();
    },
    remove(selectedCaseId) {
      this.campaignService.deleteCase(this.removeCallback, selectedCaseId);
      this.keyFile = Math.random();
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    }
  }
};
</script>