<template>
  <div>
    <div class="cookie" v-if="show">
      <div class="cookie-content">
        <v-layout align-center justify-space-between>
          <v-flex>
            <div class="pr-3">
              Utilizamos cookies essenciais e tecnologias semelhantes de acordo
              com a nossa
              <a href="javascript:;" @click="showPolicyTerms = true"
                >Política de Privacidade</a
              >
              e, ao continuar navegando você concorda com estas condições.
            </div>
            <v-layout>
              <v-switch
                v-model="selectNecessary"
                inset
                :label="'Necessários'"
                disabled
              >
              </v-switch>
              <v-switch v-model="selectFunctional" inset :label="'Funcionais'">
              </v-switch>
              <v-switch v-model="selectStastical" inset :label="'Estáticos'">
              </v-switch>
              <v-switch v-model="selectMarketing" inset :label="'Marketing'">
              </v-switch>
            </v-layout>
          </v-flex>
          <v-flex class="flex-btns">
            <v-layout justify-space-between>
              <v-btn class="px-5 btn-outlined" @click="denyCookie"
                >Aceitar selecionados</v-btn
              >
              <v-btn class="px-5 text-dark" @click="agreeCookie"
                >Aceitar todos</v-btn
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <PrivacyPolicyTermsModal
      v-model="showPolicyTerms"
      @close="showPolicyTerms = false"
    ></PrivacyPolicyTermsModal>
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";

export default {
  components: {
    Modal,
    PrivacyPolicyTermsModal,
  },
  data() {
    return {
      show: false,
      selectFunctional: false,
      selectStatical: false,
      selectMarketing: false,
      selectNecessary: true,
      showPolicyTerms: false,
    };
  },
  created() {
    var chosenOption = localStorage.getItem("cookie-agree");
    if (chosenOption != "true" && chosenOption != "false") this.show = true;
  },
  methods: {
    open() {
      this.show = true;
    },
    agreeCookie() {
      localStorage.setItem("cookie-agree", true);
      this.show = false;
      this.$emit("close");
    },
    denyCookie() {
      if (!document.__defineGetter__) {
        Object.defineProperty(document, "cookie", {
          get: function () {
            return "";
          },
          set: function () {
            return true;
          },
        });
      } else {
        document.__defineGetter__("cookie", function () {
          return "";
        });
        document.__defineSetter__("cookie", function () {});
      }
      localStorage.setItem("cookie-agree", false);
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>