import File from "@/scripts/models/file.model";
export default class CampaignPhotoCard {

	constructor() {
		this.id = 0;
		this.campaignId = 0;		
		this.title = '';
		this.subtitle = '';
		this.text = '';
		this.file = new File()
	}
}