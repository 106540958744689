import BaseCrudService from "./baseCrud.service";

export default class CheckoutSimplifiedQuestionService extends BaseCrudService {

	constructor() {
		super('CheckoutSimplifiedQuestion');
	}

	getQuestionsWithOptionsByCampaignId(campaignId) {
		return this.getRequest("GetQuestionsWithOptionsByCampaignId", { campaignId });
	}

}