<template>
	<span>
		<v-navigation-drawer class="color-palette font-sys" right v-model="drawer" temporary fixed>
			<h4>Paleta de Cores</h4>
			<div>
				<ul class="color-list">
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([0])">
							<span class="bg-theme-primaria"></span> Cor Principal
						</a>
					</li>
					<li>
						<Chrome :value="getColor(0)" v-show="showColorSelection[0]" @input="changeColor($event, 0)"></Chrome>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([1])">
							<span class="bg-theme-secundaria"></span> Cor Secundária
						</a>
					</li>
					<li>
						<Chrome :value="getColor(1)" v-show="showColorSelection[1]" @input="changeColor($event, 1)"></Chrome>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([2])">
							<span class="bg-theme-destaque"></span> Cor Destaque
						</a>
					</li>
					<li>
						<Chrome :value="getColor(2)" v-show="showColorSelection[2]" @input="changeColor($event, 2)"></Chrome>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([3])">
							<span class="bg-theme-fundo"></span> Cor de Fundo
						</a>
					</li>
					<li>
						<Compact
							:value="getColor(3)"
							v-show="showColorSelection[3]"
							@input="changeColor($event, 3)"
							:palette="[ 
								'#F1F2F5', '#F5F3F1', '#F3F1F5', '#F6F7F8', '#F8F8F8', '#F1F4F8'
							]"
						></Compact>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([4])">
							<span class="bg-theme-padrao"></span> Cinza Padrão
						</a>
					</li>
					<li>
						<Compact
							:value="getColor(4)"
							v-show="showColorSelection[4]"
							@input="changeColor($event, 4)"
							:palette="[ 
								'#D8DAE1', '#CBC7C4', '#C3BFC8', '#B9C2C8', '#C6C6C6', '#9A9FA2'
							]"
						></Compact>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([5, 6])">
							<span class="bg-theme-gradiente-principal"></span> Gradiente Principal
						</a>
					</li>
					<li>
						<Chrome :value="getColor(5)" v-show="showColorSelection[5]" @input="changeColor($event, 5)"></Chrome>
					</li>
					<li style="top: 250px;">
						<Chrome :value="getColor(6)" v-show="showColorSelection[6]" @input="changeColor($event, 6)"></Chrome>
					</li>
					<li class="color">
						<a href="#" @click.prevent="toggleColorSelection([7])">
							<span class="bg-theme-texto"></span> Cor do Texto
						</a>
					</li>
					<li>
						<Compact
							:value="getColor(7)"
							v-show="showColorSelection[7]"
							@input="changeColor($event, 7)"
							:palette="[ 
								'#000000', '#423F3C', '#504C54', '#4B5358', '#666363', '#080919', '#003951',
								'#0c4817', '#586514', '#653e14', '#65145e', '#146165', '#6b5b55', '#384b5f'
							]"
						></Compact>
					</li>
				</ul>
			</div>
			<div class="mt-4">
				<InputSelect
					:items="fontListDestaque"
					textLabel="Fonte Destaques"
					helpLabel="Fonte dos Títulos"
					@input="changeFont('--font-destaques', $event)"
					v-model="fd"
				></InputSelect>
				<InputSelect
					:items="fontListPrincipal"
					textLabel="Fonte Principal"
					helpLabel="Fonte dos Textos"
					@input="changeFont('--font-principal', $event)"
					v-model="fp"
				></InputSelect>
			</div>
		</v-navigation-drawer>
		<a href="javascript:;" class="colorPalette-toggle" @click.stop="drawer = !drawer">
			<i class="fal fa-fill-drip"></i>
		</a>
	</span>
</template>

<script type="plain/text">
import { Chrome, Compact } from "vue-color";
export default {
	components: {
		Chrome,
		Compact
	},
	props: { colors: Array, fontDestaques: String, fontPrincipal: String },
	data() {
		return {
			fd: this.fontDestaques,
			fp: this.fontPrincipal,
			drawer: null,
			showColorSelection: [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false
			],
			variableNames: [
				"--theme-primaria",
				"--theme-secundaria",
				"--theme-destaque",
				"--theme-fundo",
				"--theme-padrao",
				"--theme-gradiente-start",
				"--theme-gradiente-end",
				"--theme-texto"
			],
			fontListDestaque: [
				"Alegreya",
				"Dosis",
				"Eb Garamond",
				"Hepta Slab",
				"Lato",
				"Montserrat",
				"Oswald",
				"Playfair Display",
				"Raleway",
				"Red Hat Display",
				"Roboto Slab",
				"Ubuntu",
				"Zilla Slab"
			],
			fontListPrincipal: [
				"Dosis",
				"Montserrat",
				"Lato",
				"Open Sans",
				"Red Hat Display",
				"Roboto",
				"Source Sans Pro",
				"Titillium Web",
				"Ubuntu"
			]
		};
	},
	methods: {
		getColor(index) {
			if (
				this.colors &&
				this.colors instanceof Array &&
				index < this.colors.length
			) {
				return this.colors[index];
			}
			return { r: 0, g: 0, b: 0, a: 0 };
		},
		changeColor(value, index) {
			this.$emit("colorChanged", this.variableNames[index], [
				value.rgba.r,
				value.rgba.g,
				value.rgba.b,
				value.rgba.a
			]);
		},
		changeFont(font, value) {
			this.$emit("fontChanged", font, value);
		},
		toggleColorSelection(indices) {
			let newValues = [];
			let i;
			for (i = 0; i < indices.length; i++) {
				newValues.push(!this.showColorSelection[indices[i]]);
			}
			for (i = 0; i < 8; i++) {
				this.$set(this.showColorSelection, i, false);
			}
			for (i = 0; i < indices.length; i++) {
				this.$set(this.showColorSelection, indices[i], newValues[i]);
			}
		}
	}
};
</script>