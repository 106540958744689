<template>
  <div v-if="galleryList.length > 0">
    <vue-easy-lightbox
      :visible="galleryLightboxVisible"
      :imgs="getGalleryLightboxImgs()"
      :index="galleryLightboxIndex"
      @hide="galleryLightboxVisible = false"
    ></vue-easy-lightbox>
    <div class="section image-grid">
      <div style="position:relative">
        <div class="wrapper">
          <div class="item" v-for="(image, index) in galleryList" :key="image.id">
            <div class="tools">
              <a class="fal fa-search-plus" @click="openGalleryLightbox(index)"></a>
            </div>
            <FileImage :file="image.file" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import FileImage from "@/components/FileImage.vue";
import FileService from "@/scripts/services/file.service";
import FileProjectService from "@/scripts/services/fileProject.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { FileType } from "@/scripts/models/enums/fileType.enum";
export default {
  components: {
    FileImage
  },
  props: ["project"],
  data() {
    return {
      galleryList: [],
      fileType: FileType,
      fileService: new FileService(),
      fileProjectService: new FileProjectService(),
      galleryLightboxVisible: false,
      galleryLightboxIndex: 0
    };
  },
  created() {
    this.listPhotosGallery();
  },
  methods: {
    listPhotosGallery() {
      this.fileProjectService.listByProjectIdAndType(
        this.listFileProjectCallback,
        this.project.id,
        this.fileType.ImagemGaleriaProjeto
      );
    },
    listFileProjectCallback(data) {
      this.galleryList = data;
    },
    openGalleryLightbox(index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.galleryList == null) return [];
      for (let i = 0; i < this.galleryList.length; i++) {
        if (this.galleryList[i] != null && this.galleryList[i].file != null)
          imgs.push(this.galleryList[i].file.path);
      }
      return imgs;
    }
  }
};
</script>