<template>
  <Modal
    v-model="dialog"
    modalTitle="Termo de Anuência"
    :cardTitle="true"
    :withClose="true"
    :modalFooter="true"
    myMaxWidth="640px"
    @input="$emit('close')"
  >
    <v-container fluid pa-0>
      <v-layout pa-0>
        <v-flex list-scroll sm12 style="height: 100%">
          <div class="content-text-block overflow font-sys">
            <div class="pa-4 content-terms">
              <p class="mb-0">
                Eu,
                <strong>{{ person ? person.name : "" }}</strong>
              </p>
              <p class="mb-0" v-if="person.cpf == null || person.cpf == ''">
                AUTORIZO
                <template v-if="isPayroll()">, de forma voluntária e irretratável</template>
                , que a doação especificada abaixo seja realizada em nome:
              </p>
              <p class="mb-0" v-else>
                CPF n°
                <span
                  class="notranslate"
                >{{ person ? formatterHelper.formatCpf(person.cpf) : "" }}</span>
                AUTORIZO
                <template
                  v-if="isPayroll()"
                >, de forma voluntária e irretratável</template>, que a doação especificada abaixo seja realizada em nome:
              </p>
              <BorderSeparation class="no-margin-2" />
              <div class="terms-itens">
                <p>Valor da doação:</p>
                <p class="notranslate">
                  R$ {{ formatterHelper.formatMoney(totalPrice) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(totalPrice)
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens">
                <p>Taxa do Meio de Pagamento<label v-if="cart && cart.creditCardParcelament > 1">,<br/> por parcela</label>:</p>
                <p class="notranslate">
                  R$ {{ formatterHelper.formatMoney(getTotalTaxByParcelament()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getTotalTaxByParcelament())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens">
                <p>Taxa Total do Meio de Pagamento:</p>
                <p class="notranslate">
                  R$ {{ formatterHelper.formatMoney(getTotalTax()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getTotalTax())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div class="terms-itens" v-if="cart && cart.creditCardParcelament > 1">
                <p>Parcelamento:</p>
                <p> {{cart.creditCardParcelament}}x de R$ {{ formatterHelper.formatMoney(getParcelamentValue()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getParcelamentValue())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens total">
                <p>Total a ser pago <br/>(doação + taxa):</p>
                <p class="notranslate">
                  R$ {{ formatterHelper.formatMoney(getTotalPrice()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getTotalPrice())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div class="terms-itens">
                <p>Forma de pagamento:</p>
                <p>{{ getRecorrenceName() }}</p>
              </div>
              <div class="terms-itens">
                <p>Meio de pagamento:</p>
                <p>{{ showPaymentMethodText() }}</p>
              </div>
              <div class="terms-itens">
                <p>Campanha:</p>
                <p>{{ getCampaignName() }}</p>
              </div>
              <div class="terms-itens">
                <p>Responsável pela campanha:</p>
                <p>{{ entityName }}</p>
              </div>
              <div class="terms-itens">
                <p>CNPJ:</p>
                <p>{{ formatterHelper.formatCnpj(entityCNPJ) }}</p>
              </div>
              <div class="terms-itens mt-5">
                <p>Resumo</p>
                <p>Valor da doação</p>
              </div>
              <template v-for="item in getCartItems()">
                <div
                  class="terms-itens"
                  :key="item.id"
                  v-if="item.project && item.project.projectTypeId != ProjectTypes.Ticket"
                >
                  <p>
                    <strong>{{ item.project.shortName }}</strong>
                  </p>
                  <p class="notranslate">
                    <strong class="notranslate">
                      {{ coin.prefix }}
                      {{ formatterHelper.formatMoney(item.itemValue) }}
                    </strong>
                  </p>
                </div>
                <div class="terms-itens" :key="item.id" v-else>
                  <p>
                    <strong>{{ item.donationProduct.name }}</strong>
                  </p>
                  <p class="notranslate">
                    <strong>
                      {{ item.donationProduct.currentQuantity }}x R$
                      {{
                        formatterHelper.formatMoney(
                          item.donationProduct.openValue ? item.itemValue : item.donationProduct.fixedValue
                        )
                      }}
                    </strong>
                  </p>
                </div>
              </template>
            </div>
            <v-card-actions class="pa-0 bg-white mt-4" style="height: auto; display: flex">
              <template v-if="showTextArea" class="mt-3">
                <div class="bg-white tarea pa-2" v-html="generalText"></div>
              </template>
              <template v-else >
                <div v-html="generalText"></div>
              </template>
            </v-card-actions>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <template v-slot:footer>
      <v-container fluid>
        <v-layout>
          <v-flex text-xs-center>
            <v-btn
              flat
              round
              color="white"
              class="btn-primary px-5 text-uppercase"
              large
              @click="acceptConsentTerm"
            >Concordo</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import PersonService from "@/scripts/services/person.service.js";
import CampaignService from "@/scripts/services/campaign.service.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import Person from "@/scripts/models/person.model.js";
import Cart from "@/scripts/models/cart.model";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
export default {
  props: {
    value: Boolean,
    cart: {
      type: Object,
      default: new Cart(),
    },
    coin: {
      id: Number,
      name: String,
      prefix: String,
    },
    campaignLandingPageConfig: Object,
    recurrencePeriodList: {
      type: Array,
      default: [
        {
          id: 1,
          name: "Única"
        },
        {
          id: 2,
          name: "Recorrência Mensal"
        },
      ]
    },
    totalPrice: Number,
    parcelament: String,
    taxValue: {
      type: Number,
      required: false,
    },
    entityName: String,
    entityCNPJ: String,
    personProp: {
      type: Object,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      dialog: false,
      formatterHelper: new FormatterHelper(),
      PaymentMethod: PaymentMethod,
      personService: new PersonService(),
      campaignService: new CampaignService(),
      person: new Person(),
      ProjectTypes: ProjectTypes,
      creditCardText: `DECLARO que minha doação é voluntária.
                      Declaro ciência que para as transações de CARTÃO DE CRÉDITO, considerando principalmente sua finalidade social, não estará passível para pedido de “CHARGEBACK” junto a operadora / emissora do cartão de crédito utilizado. Declaro ainda ser responsável pelo cartão de crédito utilizado e estar ciente de que essa operação, pela sua natureza não está passível de estorno ou devolução após o seu processamento, salvo em ocorrências fraudulentas, devidamente comprovadas.`,
      payrollText: `DECLARO estar ciente que no pagamento via "Desconto em folha" com ou sem parcelamento:
                    - O referido valor não ultrapassa o limite de 30% do total de deduções do meu holerite;
                    - No caso de doações recorrentes, o cancelamento pode ser realizado a qualquer momento;
                    - No caso de desligamento ou pedido de demissão antes da efetivação dos descontos, o valor da doação será descontado integralmente na rescisão do contrato de trabalho;

                    DECLARO ainda estar ciente, que para as DOAÇÕES VIA INCENTIVO FISCAL, que as expectativas de dedução/restituição de Imposto de Renda decorrentes das doações ora efetivadas são de minha exclusiva responsabilidade.
                    - Das regras da legislação que regem essa modalidade de doação (destinação do IR – específico para optantes da declaração do Imposto de Renda no modelo completo) e as compensações que posso realizar na minha declaração de ajuste anual do Imposto de Renda (pessoa física);
                    - Que devo guardar o comprovante de pagamento/doação por no mínimo 5 anos para apresentação em eventual fiscalização no Imposto de Renda pessoa física`,
      accountDebitText: `DECLARO que minha doação é voluntária. Autorizo o lançamento especificado acima em débito automático autorizado no domicílio bancário informado. Estou ciente que ao optar por débito automático autorizado é necessário entrar em contato com um dos canais eletrônicos meu banco e liberar o débito da sua doação.
                          Obs - Acesse o internet banking do seu banco, busque por "Débito Automático", procure o lançamento de sua doação e faça a autorização. Esse processo é realizado apenas uma vez.`,
      defaultText: "DECLARO que minha doação é voluntária.",
      ticketText: "",
      pixText: "",
      showTextArea: true,
      showValueText: true,
    };
  },
  created() {
    // this.personService.getProfile(this.getProfileCallback);
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
  },
  computed: {
    generalText() {
      var text = "";
      if (this.isCreditCard() && this.creditCardText !== '') {
        text = this.creditCardText;
      } else if (this.isPayroll() && this.payrollText !== '') {
        text = this.payrollText;
      } else if (this.isAccountDebit() && this.accountDebitText !== '') {
        text = this.accountDebitText;
      } else if (this.isTicket() && this.ticketText !== '') {
        text = this.ticketText;
      } else if(this.isPix() && this.pixText !== ''){
        text = this.pixText;
      } else {
        text = this.defaultText;
      }
      if (this.hasTickets()) {
        text += "\n";
        var items = this.getCartItems();
        items.forEach((item, index) => {
          if (item.project && item.project.projectTypeId == ProjectTypes.Ticket) {
            text +=
              "REGRAS E CONDIÇÕES (" +
              item.donationProduct.name +
              ")\n" +
              item.donationProduct.rulesAndConditions;
            if (index != items.length - 1) text += "\n\n";
          }
        });
      }
      text === this.defaultText
        ? (this.showTextArea = false)
        : (this.showTextArea = true);
      return text;
    },
  },
  methods: {
    getTotalTaxByParcelament(){
      var totalValue = this.taxValue;
      if (this.isCreditCard()) {
        totalValue = this.taxValue / (this.cart.creditCardParcelament > 1 ? this.cart.creditCardParcelament : 1);
      }
      return totalValue;
    },
    getTotalTax(){
      return this.taxValue * ((this.cart.creditCardParcelament > 1 && !this.isCreditCard()) ? this.cart.creditCardParcelament : 1);
    },
    getParcelamentValue(){
      var totalValue = (this.totalPrice / this.cart.creditCardParcelament) + this.cart.taxValue;

      if (this.isCreditCard()) {
        totalValue = (this.totalPrice / this.cart.creditCardParcelament) + (this.cart.taxValue / this.cart.creditCardParcelament);
      }
      if (this.isPix()) {
        totalValue = ((this.totalPrice + this.cart.taxValue) / this.cart.creditCardParcelament)
      }

      return totalValue;
    },
    getTotalPrice(){
      var totalValue = this.totalPrice + (this.taxValue * (this.cart.creditCardParcelament > 1 ? this.cart.creditCardParcelament : 1));

      if (this.isCreditCard()) {
        totalValue = this.totalPrice + this.taxValue;
      }

      return totalValue;
    },
    open() {
      var translate = google.translate.TranslateElement().c;
      if (translate == "en") {
        this.showValueText = false;
      } else {
        this.showValueText = true;
      }
      if (this.personProp != null) this.person = this.personProp;
      else {
        if (this.person.id == 0)
          this.personService.getProfile(this.getProfileCallback);
        else this.dialog = true;
      }
      if (!this.campaignLandingPageConfig.campaign.consentTermDefault)
        this.loadCampaignConsentTerms();
    },
    loadCampaignConsentTerms() {
      this.campaignService.listConsentTermsByCampaignId(
        this.campaignLandingPageConfig.campaign.id,
        (data) => {
          data.forEach((consent) => {
            if (consent.paymentMethodId == PaymentMethod.PayrollDeduction)
              this.payrollText = consent.term;
            if (consent.paymentMethodId == PaymentMethod.CreditCard)
              this.creditCardText = consent.term;
            if (consent.paymentMethodId == PaymentMethod.AccountDebit)
              this.accountDebitText = consent.term;
            if (consent.paymentMethodId == PaymentMethod.Ticket)
              this.ticketText = consent.term;
            if (consent.paymentMethodId == PaymentMethod.Pix) {
              this.pixText = consent.term;
            }
          });
        }
      );
    },
    acceptConsentTerm() {
      this.dialog = !this.dialog;
      this.$emit("accept");
    },
    getProfileCallback(data) {
      this.person = data;
      this.dialog = true;
    },
    getCampaignName() {
      if (
        this.campaignLandingPageConfig &&
        this.campaignLandingPageConfig.campaign
      )
        return this.campaignLandingPageConfig.campaign.name;
      return "";
    },
    getCartItems() {
      if (this.cart != null && this.cart.cartItems.length > 0)
        return this.cart.cartItems;

      return [];
    },
    getRecorrenceName() {
      if (this.recurrencePeriodList.length) {
        var current = this.recurrencePeriodList.find(
          (item) => item.id == this.cart.donationRecurrencePeriodId
        );
        if (current && current.id == 1 && this.cart.creditCardParcelament > 1) 
          return "Parcelado";
        if (current && current.id == 1) {
          return "À vista"
        }
        return "Recorrente"
      }
      return "";
    },
    isCreditCard() {
      if (this.cart && this.cart.paymentMethodId == PaymentMethod.CreditCard) {
        return true;
      }
      return false;
    },
    isAccountDebit() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.AccountDebit
      ) {
        return true;
      }
      return false;
    },
    isPayroll() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.PayrollDeduction
      ) {
        return true;
      }
      return false;
    },
    isPix() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.Pix
      ) {
        return true;
      }
      return false;
    },
    isTicket() {
      if (this.cart && this.cart.paymentMethodId == PaymentMethod.Ticket) {
        return true;
      }
      return false;
    },
    hasTickets() {
      var items = this.getCartItems();
      for (let i = 0; i < items.length; i++) {
        if (items[i].project && items[i].project.projectTypeId == ProjectTypes.Ticket) return true;
      }
      return false;
    },
    showPaymentMethodText() {
      if (this.cart) {
        if (
          this.cart.paymentMethodId == PaymentMethod.CreditCard &&
          this.cart.checkoutRequest &&
          this.cart.checkoutRequest.creditCardInfo &&
          this.cart.checkoutRequest.creditCardInfo.cardNumber
        ) {
          return "Cartão de Crédito - " + this.showCardNumber();
        } else if (this.cart.paymentMethodId == PaymentMethod.Ticket) {
          return "Boleto";
        } else if (this.cart.paymentMethodId == PaymentMethod.AccountDebit) {
          return "Débito em conta";
        } else if (this.cart.paymentMethodId == PaymentMethod.Pix) {
          return "Pix";
        } else if (
          this.cart.paymentMethodId == PaymentMethod.PayrollDeduction
        ) {
          if (this.parcelament) {
            return this.parcelament.description;
          }
          return "Desconto em folha"
        }
      }
      return "";
    },
    showCardNumber() {
      var cardNumber = this.cart.checkoutRequest.creditCardInfo.cardNumber;
      var result = cardNumber.substr(cardNumber.length - 4);
      return "**** **** **** " + result;
    },
  },
};
</script>
