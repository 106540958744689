<template>
  <div>
    <Modal
      :cardTitle="true"
      :withClose="false"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="show"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <div style="padding: 30px; text-align: justify;">
            <div v-html="testimony.text"></div>
          </div>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex text-xs-center>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 text-uppercase"
                large
                @click="close"
              >Fechar</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import GoalsValues from "@/scripts/models/goalsValues.model";
import GoalsValuesFile from "@/scripts/models/goalsValuesFile.model";
import File from "@/scripts/models/file.model";
import ProjectService from "@/scripts/services/project.service.js";
export default {
  components: {
    Modal
  },
  data() {
    return {
      show: false,
      testimony: { text: "" },
      title: ""
    };
  },
  methods: {
    open(testimony, title) {
      this.testimony = testimony;
      this.title = title;
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
};
</script>