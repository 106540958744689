<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="620px">
      <template v-slot:activator="{ on }">
        <v-btn flat round class="btn mt-3 btn-white font-sys" large v-on="on" @click="link = value">
          Link do vídeo
          <i class="ml-3 fal fa-link"></i>
        </v-btn>
      </template>
      <v-card>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout w100 d-flex align-start>
            <v-flex xs12 class="py-3 px-4 display-flex justify-center align-center f-500-column">
              <InputText
                v-model="link"
                type="text"
                place-holder="Insira o link do video"
                textLabel="Vídeo - Youtube"
                helpLabel="Ex: https://www.youtube.com/embed/TJ5xnIEOFvw"
              />
              <v-btn
                flat
                round
                color="white"
                class="d-flex btn btn-primary px-4 mr-0"
                large
                @click="save()"
              >Salvar</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import CommonHelper from "@/scripts/helpers/common.helper";
export default {
  props: ["value"],
  data() {
    return {
      dialog: false,
      link: this.value
    };
  },
  methods: {
    save() {
	  let videoHash = CommonHelper.validateYoutubeLink(this.link);
      if (videoHash && videoHash.length == 11) {
        this.$emit("input", this.link);
        this.$emit("saveLink");
        this.dialog = false;
      } else {
        CommonHelper.swal("Link inválido");
      }
    }
  }
};
</script>
