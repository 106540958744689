import File from "@/scripts/models/file.model";
import { FileType } from "@/scripts/models/enums/fileType.enum";

export default class CampaignNews {

	constructor() {
		this.id = 0;
		this.campaignId = 0;
		this.title = '';
		this.body = '';
		this.file = new File();
		this.campaignFileType = FileType.ImagemNoticiaCampanha;
		this.imageContent = null;
	}
}