<template>
  <v-layout row justify-center>
    <DonationStepEditIcons
      ref="donationStepEditIconComponent"
      @close="modalClosed"
    />

    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn
          flat
          round
          class="btn btn-white px-5 mt-3 font-sys"
          large
          v-on="on"
        >
          Editar passos
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Passo a passo para doar</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-layout w100 d-flex mobile-r-column mobile-modal-campaign>
            <v-flex
              md6
              xs12
              class="pt-4 border-horizontal display-flex f-d-column"
            >
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-5 mb-2"
                large
                @click="create"
                :disabled="selectedDonationStepId == 0"
              >
                <i class="far fa-plus mr-2"></i>Novo passo
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal"
                  :class="{
                    active: selectedDonationStepId === donationStep.id,
                  }"
                  @click="selectDonationStep(donationStep.id)"
                  v-for="donationStep in donationStepList"
                  :key="donationStep.id"
                >
                  <div>{{ donationStep.title }}</div>
                  <span v-html="donationStep.body"></span>
                </div>
              </div>
            </v-flex>
            <v-flex
              class="pt-3 box-heart-father"
              md6
              xs12
              :class="{ active: showForm }"
            >
              <div class="list-scroll">
                <v-flex px-3>
                  <div class="box-heart-step">
                    <div>
                      <label>Ícone</label>
                      <i :class="iconClass" @click="openIconsModal"></i>
                    </div>
                    <div>
                      <InputText
                        type="text"
                        place-holder="Título"
                        textLabel="Título"
                        v-model="campaignDonationStep.title"
                      />
                    </div>
                    <div>
                      <div class="display-flex align-item-center flex-gap">
                        <input
                          type="checkbox"
                          id="vaga"
                          v-model="campaignDonationStep.showNumber"
                        />
                        <label for="vaga">Mostrar número</label>
                      </div>
                    </div>
                  </div>
                  <div class="t-area-heart-step">
                    <!-- <InputTextArea
                      place-holder="Descreva o passo"
                      textLabel="Corpo do passo"
                      v-model="campaignDonationStep.body"
                    ></InputTextArea>-->
                    <vue-editor
                      v-model="campaignDonationStep.body"
                      :editor-toolbar="customToolbar"
                    />
                  </div>
                </v-flex>
              </div>
              <v-flex
                class="align-center justify-space-between footer-step-modal"
              >
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="
                    btn-back-modal
                    d-flex
                    theme--light
                    btn-default
                    px-0
                    ma-0
                    v-btn v-btn--flat v-btn--large v-btn--round
                    theme--light
                    white--text
                  "
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="
                    openConfirmDeleteDonationStepModal(campaignDonationStep.id)
                  "
                  :disabled="selectedDonationStepId == 0"
                  >Excluir</v-btn
                >
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                  >Salvar</v-btn
                >
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse passo?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedDonationStepId)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import DonationStepEditIcons from "@/components/DonationStepEditIcons.vue";
import CampaignDonationStep from "@/scripts/models/campaignDonationStep.model";
import CampaignService from "@/scripts/services/campaign.service";
import { VueEditor } from "vue2-editor";
import InputCheckBoxList from "./input/InputCheckBoxList.vue";

export default {
  components: {
    AucAlert,
    VueEditor,
    DonationStepEditIcons,
    InputCheckBoxList,
  },
  props: {
    value: Array,
    campaignId: 0,
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      campaignService: new CampaignService(),
      campaignDonationStep: new CampaignDonationStep(),
      donationStepList: this.value,
      selectedDonationStepId: null,
      dialog: false,
      showForm: true,
      iconClass: "",
      isSelectingIcon: false,
    };
  },
  computed: {
    isValid() {
      if (
        this.campaignDonationStep &&
        this.campaignDonationStep.title &&
        this.campaignDonationStep.body
      )
        return true;
      return false;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        if (!this.isSelectingIcon) {
          this.create();
        } else {
          this.isSelectingIcon = false;
        }
      }
    },
  },
  methods: {
    openConfirmDeleteDonationStepModal(id) {
      this.selectedDonationStepId = id;
      this.$refs.confirmationComponent.open();
    },
    openIconsModal() {
      this.$refs.donationStepEditIconComponent.open();
      this.dialog = false;
      this.isSelectingIcon = true;
    },
    listByCampaignId() {
      this.campaignService.listDonationStepsByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.donationStepList = data;
      this.$emit("input", data);
    },
    selectDonationStep(donationStepId) {
      let donationStep = this.donationStepList.find(
        (donationStep) => donationStep.id == donationStepId
      );

      if (donationStep != undefined) {
        this.selectedDonationStepId = donationStepId;
        this.copy(donationStep);
      }
    },
    copy(donationStep) {
      this.campaignDonationStep = new CampaignDonationStep();
      this.campaignDonationStep.id = donationStep.id;
      this.campaignDonationStep.campaignId = donationStep.campaignId;
      this.campaignDonationStep.title = donationStep.title;
      this.campaignDonationStep.body = donationStep.body;
      this.campaignDonationStep.icon = donationStep.icon;
      this.campaignDonationStep.showNumber = donationStep.showNumber;
      this.iconClass = this.campaignDonationStep.icon;
    },
    create() {
      this.selectedDonationStepId = 0;
      this.campaignDonationStep = new CampaignDonationStep();
      this.iconClass = this.campaignDonationStep.icon;
    },
    save() {
      this.campaignDonationStep.campaignId = this.campaignId;
      this.campaignService.saveDonationStep(
        this.saveCallback,
        this.campaignDonationStep
      );
    },
    saveCallback(data) {
      this.listByCampaignId();
      this.create();
    },
    remove(selectedDonationStepId) {
      this.campaignService.deleteDonationStep(
        this.removeCallback,
        selectedDonationStepId
      );
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    },
    modalClosed(value) {
      this.selectDonationStep(this.selectedDonationStepId);
      this.dialog = true;
      if (value != "") {
        this.campaignDonationStep.icon = value;
        this.iconClass = value;
      }
    },
  },
};
</script>