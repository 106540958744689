export default class GoalsValues {

    constructor() {
        this.id = 0;
        this.goalId = 0;
        this.order = 0;
        this.statusId = 0;
        this.initialDate = "";
        this.finalDate = "";
        this.value = 0;
        this.realized = 0;
        this.canceled = false;
        this.title = "";
        this.description = "";
        this.goalsValuesFiles = [];
        this.sentNotification = false;
    }
}