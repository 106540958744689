import File from "@/scripts/models/file.model";
import { FileType } from "@/scripts/models/enums/fileType.enum";

export default class CampaignTestimony {

	constructor() {
		this.id = 0;
		this.campaignId = 0;
		this.file = new File();
		this.name = '';
		this.position = '';
		this.text = '';
		this.campaignFileType = FileType.ImagemDepoimentoCampanha;
		this.imageContent = null;
		this.photoSquare = false;
	}
}