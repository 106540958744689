<template>
  <div class="container-fluid w-100">
    <v-layout row wrap father-voucher>
      <v-flex voucher-card>
        <h2
          :contenteditable="editing"
          spellcheck="false"
          data-title="Título"
          @blur="$emit('updateProperty', 'donationVoucherTitle', $event)"
          v-html="campaignLandingPageConfig.donationVoucherTitle"
        ></h2>
        <!-- <h2 :contenteditable="true">Sua empresa pode fazer uma doação <br /> diretamente para a campanha.</h2> -->
        <div class="voucher-card-item">
          <v-layout row>
            <v-flex class="data-account bank">
              BANCO
              <div
                :contenteditable="editing"
                spellcheck="false"
                data-title="Banco"
                @blur="$emit('updateProperty', 'donationVoucherBank', $event)"
                v-html="campaignLandingPageConfig.donationVoucherBank"
              ></div>
              <!-- <span :contenteditable="true">Bradesco</span> -->
            </v-flex>
            <v-flex class="data-account agency">
              AGÊNCIA
              <div
                :contenteditable="editing"
                spellcheck="false"
                data-title="Agência"
                @blur="$emit('updateProperty', 'donationVoucherAgency', $event)"
                v-html="campaignLandingPageConfig.donationVoucherAgency"
              ></div>
              <!-- <span>243-8</span> -->
            </v-flex>
            <v-flex class="data-account account">
              CONTA CORRENTE
              <div
                :contenteditable="editing"
                spellcheck="false"
                data-title="Conta Corrente"
                @blur="$emit('updateProperty', 'donationVoucherAccount', $event)"
                v-html="campaignLandingPageConfig.donationVoucherAccount"
              ></div>
              <!-- <span>034534-2</span> -->
            </v-flex>
          </v-layout>
          <v-layout row mt-3>
            <v-flex class="data-account">
              CNPJ
              <div
                :contenteditable="editing"
                spellcheck="false"
                data-title="CNPJ"
                @blur="$emit('updateProperty', 'donationVoucherCNPJ', $event)"
                v-html="campaignLandingPageConfig.donationVoucherCNPJ"
              ></div>
              <!-- <span>123421543/0001-23</span> -->
            </v-flex>
          </v-layout>
          <v-layout row mt-3>
            <v-flex class="data-account">
              RAZÃO SOCIAL
              <div
                :contenteditable="editing"
                spellcheck="false"
                data-title="Razão Social"
                @blur="$emit('updateProperty', 'donationVoucherSocialReason', $event)"
                v-html="campaignLandingPageConfig.donationVoucherSocialReason"
                style="line-height:1; overflow:initial;"
              ></div>
              <!-- <span>Lorem ipsum dolor sit amet</span> -->
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex voucher-dialog with-bg
        :style="`background-image: url(https://via.placeholder.com/800)`">
        <div>
          <!-- <h3>Sua empresa fez uma doação?</h3> -->
          <h3
            :contenteditable="editing"
            spellcheck="false"
            data-title="Sub Título"
            @blur="$emit('updateProperty', 'donationVoucherSubTitle', $event)"
            v-html="campaignLandingPageConfig.donationVoucherSubTitle"
          ></h3>
          <p
            class="mt-2"
            :contenteditable="editing"
            spellcheck="false"
            data-title="Parágrafo"
            @blur="$emit('updateProperty', 'donationVoucherParagraph', $event)"
            v-html="campaignLandingPageConfig.donationVoucherParagraph"
          ></p>
          <!-- <p class="mt-2">Qualem igitur hominem natura inchoavit? De ingenio eius <br /> in his disputationibus, non de moribus quaeritur.</p> -->
          <v-btn
            flat
            round
            color="white"
            class="bg-theme-primaria mx-0 mt-3"
            @click="toggleDialog(); $gtag('send', 'event', 'Enviar comprovante de doacao PJ', 'Envio comprovante doação PJ', 'Comprovante doação PJ')"
            large
          >
            <Icon name="fal fa-envelope-open-dollar mr-3" size="16" colorFont="#fff" />Enviar comprovante
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <ModalVoucher
      v-model="dialog"
      modalTitle="Comprovante de doação"
      :cardTitle="true"
      :withClose="true"
      :modalFooter="true"
      myMaxWidth="730px"
    >
      <v-container fluid pa-0>
        <v-layout pa-0>
          <v-flex list-scroll sm12 style="height: 100%">
            <div class="content-text-block overflow font-sys">
              <v-layout row wrap>
                <v-flex xs12>
                  <InputText
                    ref="responsibleNameInput"
                    type="text"
                    place-holder="Informe o nome do contato responsável"
                    textLabel="Nome responsável"
                    v-model="campaignDonationReceipt.responsibleName"
                    invalidInputMessage="Preencha o nome do contato responsável"
                  />
                </v-flex>
                <v-flex xs6 pr-3>
                  <InputText
                    ref="phoneInput"
                    type="tel"
                    place-holder="Informe o telefone do contato"
                    textLabel="Telefone"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    v-model="campaignDonationReceipt.phone"
                    :validation="validateCellPhone"
                    invalidInputMessage="Preencha o telefone"
                  />
                </v-flex>
                <v-flex xs6>
                  <InputText
                    ref="emailInput"
                    type="text"
                    place-holder="Informe o e-mail do contato"
                    textLabel="E-mail"
                    v-model="campaignDonationReceipt.email"
                    :validation="validateEmail"
                    invalidInputMessage="Por favor informe um e-mail válido"
                  />
                </v-flex>
              </v-layout>
              <div class="company-info-head">
                <h5>Empresas</h5>
                <v-btn
                  flat
                  round
                  color="white"
                  class="bg-theme-primaria mx-0"
                  large
                  @click="addNewCompany"
                >
                  <Icon name="fal fa-plus mr-3" size="16" colorFont="#fff" />Empresa
                </v-btn>
              </div>
              <template
                v-for="(company, index) in campaignDonationReceipt.campaignCompanyDonationReceiptList"
              >
                <v-layout row wrap box-replace-voucher :key="index">
                  <v-flex xs4 pr-3>
                    <InputText
                      ref="socialReasonInput"
                      type="text"
                      textLabel="Razão Social"
                      v-model="company.socialReason"
                      invalidInputMessage="Preencha a razão social"
                    />
                  </v-flex>
                  <v-flex xs4 pr-3>
                    <InputText
                      ref="cnpjInput"
                      v-model="company.cnpj"
                      type="tel"
                      place-holder="CNPJ"
                      textLabel="CNPJ"
                      invalidInputMessage="Preencha o CNPJ da Empresa"
                      mask="##.###.###/####-##"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap box-replace-voucher :key="index">
                  <v-flex xs4 pr-3>
                    <!-- <InputText
                      ref="donationValueInput"
                      v-model="company.donationValue"
                      type="tel"
                      textLabel="Valor doação"
                      invalidInputMessage="Preencha o valor da doação"
                    />-->
                    <InputMoney
                      ref="donationValueInput"
                      textLabel="Valor doação"
                      v-model="company.donationValue"
                      :precision="2"
                      invalidInputMessage="Preencha o valor da doação"
                    />
                  </v-flex>
                  <v-flex xs4 pr-3>
                    <InputDate
                      ref="donationDateInput"
                      class="input-date-voucher"
                      v-model="company.donationDate"
                      textLabel="Data da doação"
                      :max="$moment().format('YYYY-MM-DD')"
                    ></InputDate>
                  </v-flex>
                  <v-flex xs4 class="options-company-voucher">
                    <label>{{ company.file.name }}</label>
                    <div class="box-upload-voucher">
                      <label :for="'upload-voucher-dialog' + index">
                        <Icon name="fal fa-cloud-upload" size="16" colorFont="#fff" />
                      </label>
                      <input
                        ref="upload_voucher"
                        type="file"
                        :id="'upload-voucher-dialog' + index"
                        @change="fileChanged($event, index)"
                        accept="application/pdf, image/*"
                      />
                    </div>
                    <button @click="removeCompany(index)">
                      <Icon name="fal fa-trash" size="16" colorFont="#C3C6D5" />
                    </button>
                  </v-flex>
                </v-layout>
              </template>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <template v-slot:footer>
        <v-container fluid>
          <v-layout>
            <v-flex text-xs-right>
              <v-btn
                flat
                round
                color="white"
                class="btn-gray px-5 mr-4"
                large
                @click="toggleDialog"
              >Cancelar</v-btn>
              <v-btn flat round color="white" class="btn-primary px-5" large @click="isValid">Enviar</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ModalVoucher>
  </div>
</template>
<script type="plain/text">
import CampaignService from "@/scripts/services/campaign.service";
import ModalVoucher from "@/components/Modal.vue";
import CampaignDonationReceipt from "@/scripts/models/campaignDonationReceipt.model";
import CampaignCompanyDonationReceipt from "@/scripts/models/campaignCompanyDonationReceipt.model";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import CommonHelper from "@/scripts/helpers/common.helper";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  components: {
    ModalVoucher
  },
  props: ["editing", "campaignLandingPageConfig"],
  data() {
    return {
      dialog: false,
      campaignDonationReceipt: new CampaignDonationReceipt(),
      campaignService: new CampaignService()
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  created() {
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.validationSuccess;
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
      this.$emit("input", this.dialog);
    },
    save() {
      this.campaignDonationReceipt.campaignId = this.campaignLandingPageConfig.campaignId;
      this.campaignService
        .saveDonationReceipt(this.campaignDonationReceipt)
        .then(data => {
          if (data) {
            this.$store.commit(
              "SET_SNACKBAR_SUCCESS_MESSAGE",
              "Mensagem enviada com sucesso"
            );
          }
        });
    },
    addNewCompany() {
      let newCompany = new CampaignCompanyDonationReceipt();
      let list = this.campaignDonationReceipt
        .campaignCompanyDonationReceiptList;

      newCompany.Order = GetCurrentOrder(
        this.campaignDonationReceipt.campaignCompanyDonationReceiptList
      );

      if (
        list.length == 0 ||
        (list[list.length - 1].socialReason != "" &&
          list[list.length - 1].cnpj != "" &&
          list[list.length - 1].donationValue != "" &&
          list[list.length - 1].donationDate != "")
      ) {
        this.campaignDonationReceipt.campaignCompanyDonationReceiptList.push(
          newCompany
        );
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Preencha os todos os campos anteriores antes de adicionar outra empresa"
        );
      }
    },
    removeCompany(index) {
      this.campaignDonationReceipt.campaignCompanyDonationReceiptList.splice(
        index,
        1
      );
    },
    fileChanged(event, index) {
      var file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function() {
          this.campaignDonationReceipt.campaignCompanyDonationReceiptList[
            index
          ].file.name = file.name;
          this.campaignDonationReceipt.campaignCompanyDonationReceiptList[
            index
          ].file.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    validateEmail() {
      return CommonHelper.validateEmail(this.campaignDonationReceipt.email);
    },
    validateCellPhone() {
      return CommonHelper.validateCellPhone(this.campaignDonationReceipt.phone);
    },
    validate(performMethods) {
      var isValid = this.validateComponents(performMethods);
      if (isValid) {
        if (
          this.campaignDonationReceipt.campaignCompanyDonationReceiptList
            .length == 0
        ) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Adicione ao menos uma empresa."
          );
          return false;
        }

        //Validando todos os CNPJs
        for (let i = 0; i < this.$refs.cnpjInput.length; i++) {
          let valid = CommonHelper.validateCNPJ(this.$refs.cnpjInput[i].value);
          this.$refs.cnpjInput[i].valid = valid;
          if (!valid) return false;
        }
      }
      return isValid;
    },
    validationSuccess() {
      this.save();
      this.toggleDialog();
    }
  }
};
</script>