<style scoped>
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
</style>
<template>
	<Modal
		v-model="value"
		modalTitle="Termos de Uso"
		:cardTitle="true"
		:withClose="true"
		myMaxWidth="640px"
		@input="$emit('close')"
	>
		<v-container fluid pa-0 v-if="person && entity">
			<v-layout pa-0>
				<v-flex list-scroll sm12 style="height:82vh">
					<div class="content-text-block overflow font-sys">
						<pre style="white-space: normal;">
              <h2>TERMO DE ADESÃO AO TRABALHO VOLUNTÁRIO</h2><br>

                IDENTIFICAÇÃO DO VOLUNTÁRIO DA AÇÃO VOLUNTÁRIA
                {{ person.name }}, inscrito no CPF sob nº {{ CpfFormat(person.cpf) }}, residente e domiciliado na {{ person.address.street }}, {{ person.address.number }}, {{ person.address.neighborhood }}, {{ person.address.city }} - {{ person.address.state }}, doravante designado
                simplesmente “Voluntário” <br><br>
                IDENTIFICAÇÃO DA GESTORA DA AÇÃO VOLUNTÁRIA:
                {{ entity.name }}, inscrito no CNPJ sob nº {{ CnpjFormat(entity.cnpj) }}, com sede na {{ entity.address.street }}, {{ entity.address.number }}, {{ entity.address.neighborhood }}, {{ entity.address.city }} - {{ entity.address.state }} doravante designado
                simplesmente “Gestora”<br><br>
                IDENTIFICAÇÃO DA ENTIDADE APOIADA PELA AÇÃO VOLUNTÁRIA:
                {{ entity.name }}, inscrito no CNPJ sob nº {{ CnpjFormat(entity.cnpj) }}, com sede na {{ entity.address.street }}, {{ entity.address.number }}, {{ entity.address.neighborhood }}, {{ entity.address.city }} - {{ entity.address.state }}, doravante designado simplesmente “Entidade”<br><br>
                O presente Termo de Adesão tem como objeto a regulamentação de atividades não remuneradas a
                serem executadas pelo Voluntário acima identificado nos termos da Lei 9608/98, relativas à(s)
                ação(ões) gerenciadas pela Gestora e promovidas pela Entidade no âmbito do {{ info.offerName }}, sob as condições a seguir especificadas:<br><br>
                1. Por força do presente Termo de Adesão o Voluntário poderá:<br>
                a) Optar, a seu exclusivo critério e de forma eletrônica no Portal da Abrace Uma Causa pela(s)
                ação(ões) voluntária(s) em que queira atuar no âmbito do {{ info.offerName }}, sendo
                que as condições específicas relativas a cada ação estarão detalhadas neste mesmo canal de
                comunicação;<br><br>
                b) Contribuir isoladamente ou em grupo conforme características específicas de cada ação
                gerenciada pela Gestora e promovida pela Entidade, junto a Organizações Sociais, Escolas
                Públicas, Empresas Parceiras e/ou comunidade em geral, sendo permitido ao Voluntário atuar
                em atividades de gestão, pedagogia, infraestrutura de Organização Social ou escola nas
                temáticas que envolvam esporte &amp; recreação, arte &amp; cultura, meio ambiente, diversidade,
                empreendedorismo &amp; geração de renda, arrecadação e entrega de materiais, orientação
                financeira ao público em geral e/ou quaisquer outras atividades necessárias ao integral
                cumprimento das finalidades específicas relacionadas à(s) ação(ões) promovida(s) pela
                Entidade e gerenciada pela Gestora para a qual(is) o voluntário tenha escolhido atuar.<br><br>
                2. Os serviços a serem prestados pelo Voluntário no âmbito do {{ info.offerName }}
                possuem caráter gratuito, não cabendo remuneração a título de contraprestação; não havendo vínculo
                trabalhista e nem obrigação de natureza trabalhista, previdenciária ou afim.<br><br>

                3. O presente Termo de Adesão vigorará por prazo indeterminado a partir da data de sua
                assinatura, podendo ser rescindido por qualquer das Partes a qualquer tempo, devendo a outra Parte
                ser comunicada com antecedência mínima de 30 dias.<br><br>
                4. O Voluntário neste ato autoriza a Gestora e a Entidade a utilizarem e efetuar o tratamento dos
                dados relativos as imagens e a voz do Voluntário acima identificado captadas por meio de filmagens e
                fotografias, bem como das declarações, depoimentos, relatos das atividades realizadas relativas à
                atuação do Voluntário no {{ info.offerName }}, pelo prazo em que vigorar cada ação para a qual
                o voluntário tenha escolhido atuar, com o objetivo de efetuar sua divulgação e como material
                institucional da referida Ação e/ou do {{ info.offerName }}, podendo utilizar este material ou
                respectivas reproduções no mundo inteiro, , no todo ou em parte, em conexão com o {{ info.offerName }}.
                As Partes elegem o Foro Central da Comarca da Capital de São Paulo para dirimir questionamentos
                sobre este Contrato, renunciando a qualquer outro.
                E, ESTANDO ASSIM JUSTAS E CONTRATADAS, celebram as Partes o presente instrumento.<br><br>
            </pre>
            <v-btn 
              flat round color="white" class="btn-blue text-uppercase"
              @click="$emit('confirmTerms')"
              v-if="!fromDash"
            >
              Concordo
            </v-btn>
					</div>
          
				</v-flex>
			</v-layout>
		</v-container>
	</Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import PersonService from "@/scripts/services/person.service.js";

export default {
	props: {
    value: Boolean,
    terms: String,
    info: Object,
    fromDash: Boolean
  },
	components: {
		Modal, 
	},
	data() {
		return {
      personService: new PersonService(),
      dialog: false,
      person: null,
		};
	},
  computed: {
    entity() {
      return this.info.institution.entity
    }
  },
  async created() {
    if (this.fromDash === null) this.fromDash = false;
  },
  mounted() {
    this.personService.getProfile(this.getProfileCallback);
  },
  watch: {
    value(val) {
			this.value = val
		}
  },
  methods: {
    getProfileCallback(data) {
      this.person = data
    },
    CpfFormat(cpf){
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, "");
      
      //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    CnpjFormat(cnpj) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    },
	}
};
</script>
