<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white px-5 mt-3 font-sys" large v-on="on">
          Editar Depoimentos
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Depoimentos</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false; resetFileInput()">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex md6 xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-5 mb-2"
                large
                @click="create"
                :disabled="selectedTestimonyId == 0"
              >
                <i class="far fa-plus mr-2"></i>Novo depoimento
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal flex"
                  :class="{ 'active': selectedTestimonyId === testimony.id }"
                  @click="selectTestimony(testimony.id)"
                  v-for="testimony in testimonyList"
                  :key="testimony.id"
                >
                  <div class="mr-3">
                    <!-- <CampaignTestimonyImage :testimony="testimony" :alt="''" /> -->
                    <FileImage 
                      :file="testimony.file" 
                      :alt="''"
                      effects="c_crop,g_face:auto,h_300,w_300,z_0.7"
					          />
                  </div>
                  <div class="flex-center">
                    <div class="name-testimony">{{ testimony.name }}</div>
                    <span v-html="testimony.position"></span>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex class="pt-3 box-heart-father" md6 xs12 :class="{active: showForm}">
              <div class="list-scroll">
                <v-flex pa-3 pb-4>
                  <!-- <v-flex class="upload-box-profile-father testimony" shrink> -->
                    <FileImageUpload
                    :maxWidth="300"
                    :maxHeight="300"
                    :quality="0.7"
                    :file="campaignTestimony.file"                  
                    :rounded="!campaignTestimony.photoSquare"
                    :accept="['image/jpg','image/png','image/jpeg']"
                    width="150px"
                    defaultClass="upload-image square"
                    unselectedText="Imagem do depoimento"
                  />
                    <!-- <div
                      class="upload-image"
                      id="preview"
                      ref="preview"
                      :style="'backgroundImage:' + getImage()"
					  effects="c_crop,g_face:auto,h_300,w_300,z_0.7"
                    >
                      <label for="upload-institution">
                        <i class="fas fa-camera-alt"></i>
                      </label>
                      <label
                        for="upload-institution"
                        class="do-upload"
                        v-show="campaignTestimony.file.path == ''"
                      >
                        <i class="fal fa-cloud-upload"></i>Foto
                      </label>
                      <input
                        type="file"
                        id="upload-institution"
                        accept="image/*"
                        @change="fileChanged"
                        ref="upload_institution"
                      />
                    </div> -->
                  <!-- </v-flex> -->
                  <div class="display-flex align-item-center flex-gap mt-4">
                    <input
                      type="checkbox"
                      id="photosquare"
                      v-model="campaignTestimony.photoSquare"
                    />
                    <label for="vaga">Foto em formato quadrado</label>
                  </div>
                  <InputText type="text" textLabel="Nome" v-model="campaignTestimony.name" />
                  <InputText type="text" textLabel="Cargo" v-model="campaignTestimony.position" />
                  <div class="t-area-heart-step">
                    <label>Descreva o depoimento</label>
                    <!-- <InputTextArea v-model="campaignTestimony.text"></InputTextArea> -->
                    <vue-editor
                      v-model="campaignTestimony.text"
                      :editor-toolbar="customToolbar"
                    />
                  </div>
                </v-flex>
              </div>
              <v-flex class="align-center justify-space-between footer-step-modal">
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="openConfirmDeleteTestimonyModal(campaignTestimony.id)"
                  :disabled="selectedTestimonyId == 0"
                >Excluir</v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                >Salvar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse depoimento?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedTestimonyId)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import CampaignTestimony from "@/scripts/models/campaignTestimony.model";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    AucAlert,
    VueEditor,
    FileImage
    // CampaignTestimonyImage
  },
  props: {
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
      campaignService: new CampaignService(),
      campaignTestimony: new CampaignTestimony(),
      testimonyList: this.value,
      selectedTestimonyId: null,
      dialog: false,
      showForm: false
    };
  },
  mounted() {
  },
  computed: {
    isValid() {
      if (
        this.campaignTestimony &&
        this.campaignTestimony.name &&
        this.campaignTestimony.position &&
        this.campaignTestimony.text &&
        this.campaignTestimony.file &&
        this.campaignTestimony.file.path
      ) {
		return true;
	  }
      return false;
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.create();
      }
    }
  },
  methods: {
    swal(title, message, type) {
      CommonHelper.swal(title, message, type);
    },
    openConfirmDeleteTestimonyModal(id) {
      this.selectedTestimonyId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listTestimoniesByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.testimonyList = data;
      this.$emit("input", data);
    },
    selectTestimony(testimonyId) {
      let testimony = this.testimonyList.find(
        testimony => testimony.id == testimonyId
      );
      this.selectedTestimonyId = testimonyId;
      this.copy(testimony);
    },
    copy(testimony) {
      this.campaignTestimony = new CampaignTestimony();
      this.campaignTestimony.id = testimony.id;
      this.campaignTestimony.campaignId = testimony.campaignId;
      this.campaignTestimony.name = testimony.name;
      this.campaignTestimony.position = testimony.position;
      this.campaignTestimony.text = testimony.text;
      this.campaignTestimony.campaignFileType = testimony.campaignFileType;
      this.campaignTestimony.file.id = testimony.file.id;
      this.campaignTestimony.file.userId = testimony.file.userId;
      this.campaignTestimony.file.name = testimony.file.name;
      this.campaignTestimony.file.path = testimony.file.path;
      this.campaignTestimony.file.size = testimony.file.size;
      this.campaignTestimony.file.extension = testimony.file.extension;
      this.campaignTestimony.file.origin = testimony.file.origin;
      this.campaignTestimony.file.publicId = testimony.file.publicId;
      this.campaignTestimony.imageContent = testimony.imageContent;
      this.campaignTestimony.photoSquare = testimony.photoSquare;
      if (
        testimony.file != null &&
        testimony.file.hasOwnProperty("imageContent")
      ) {
        this.campaignTestimony.file.imageContent = testimony.file.imageContent;
      }
    },
    create() {
      this.selectedTestimonyId = 0;
      this.campaignTestimony = new CampaignTestimony();
      CommonHelper.clearInputFile(this.$refs.upload_institution);
    },
    save() {
      this.campaignTestimony.campaignId = this.campaignId;
      this.campaignService.saveTestimony(
        this.saveCallback,
        this.campaignTestimony
      ).then(() => { 
          this.swal("Sucesso!", 'Depoimento salvo com sucesso!', "success");
          this.resetFileInput()
      })
      .catch(() => this.swal("Erro", "Não foi possível salvar este depoimento!", "error"));
    },
    saveCallback(data) {
      this.listByCampaignId();
      this.create();
    },
    remove(selectedTestimonyId) {
      this.campaignService.deleteTestimony(
        this.removeCallback,
        selectedTestimonyId
      ).then(() => {
        this.swal("Sucesso!", 'Depoimento deletado com sucesso!', "success");
      }).catch(() => this.swal("Erro", "Não foi possível deletar este depoimento!", "error"));
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    },
    resetFileInput () {
      const element = document.getElementById('upload-input')
      element.value = ''
    },
  }
};
</script>