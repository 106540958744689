export default class CampaignDonationReceipt {

	constructor() {
		this.Order = 0;
		this.campaignId = 0;
        this.responsibleName = '';
        this.phone = '';
		this.email = '';
		this.campaignCompanyDonationReceiptList = [];
	}
}