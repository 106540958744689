
<template>
  <div>
    <div id="bar-code-success" v-show="payment === 'barcode'">
      <div class="widget-px widget-pt">
        <div class="widget-form-group">
          <label for="">Código de barras</label>
          <div class="widget-form-control icon-right">
            <input type="text" value="89709 097377 093783 984732 3298373" />
            <i class="widget-listitem-icon fal fa-copy"></i>
          </div>
        </div>
        <hr class="widget-divider" />
        <div class="widget-boxinfo">
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p>
        </div>
      </div>
      <div class="widget-listitem">
        <button @click="openDoc" class="widget-btn">
          <i class="fal fa-barcode-alt"></i>
          <span>Visualizar boleto</span>
        </button>
      </div>
    </div>
    <div id="credit-card-success" v-show="payment === 'card'">
      <div class="widget-px widget-pt">
        <div class="widget-form-group">
          <div class="widget-alert">
            <i class="fas fa-check-circle"></i>
            <span>Pagamento concluído com sucesso!</span>
          </div>
        </div>
        <hr class="widget-divider" />
        <img src="/images/flags/visa.svg" alt="" />
        <h5 class="widget-pt">Cartão de crédito Visa</h5>
        <h5 class="widget-pt">**** **** **** 2334</h5>
        <hr class="widget-divider" />
        <p>
          Em instantes você receberá na sua caixa postal um e-mail de
          confirmação com o pagamento realizado.
        </p>
        <p class="widget-pt">
          Caso não receba nos próximos minutos, verifique sua caixa de spam.
        </p>
        <hr class="widget-divider" />
        <p>Número da transação:</p>
        <h5 class="widget-pt widget-pb-24">68793265</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['payment'],
    methods: {
        openDoc() {
            window.open('https://www.boletobancario.com/boletofacil/img/boleto-facil-exemplo.pdf', 'popup')
        }
    }
};
</script>

<style>
</style>