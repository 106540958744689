<template>
	<v-dialog v-model="dialog" persistent max-width="1150">
		<div class="modal-noticias">
			<div class="close-noticias">
				<button @click="close">
					<i class="fal fa-times"></i>
				</button>
			</div>
			<v-card>
				<slot></slot>
			</v-card>
		</div>
	</v-dialog>
</template>

<script type="plain/text">
export default {
	props: {
		dialog: false
	},
	methods: {
		close() {
			this.dialog = false;
			this.$emit("close");
		}
	}
};
</script>