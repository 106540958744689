<template>
  <div class="container-fluid w-100 mt-5" :class="isModal ? 'aux-cfluid' : ''">
    <v-flex
      v-if="isModal"
      display-flex
      flex-column
      mb-3
      align-center
      justify-center
    >
      <h3
        class="mt-3 mb-2 text-xs-center f-size-32 color-theme-texto font-destaque"
      >
        {{ campaignLandingPage.donationProductsTitle }}
      </h3>
    </v-flex>
    <div class="wrapper pb-3">
      <v-layout wrap display-flex justify-center>
        <template v-if="!isModal">
          <button type="button" class="scroll-arrow prev" id="agradPrevArrow">
            <Icon name="far fa-chevron-left" size="16" />
          </button>
          <button type="button" class="scroll-arrow next" id="agradNextArrow">
            <Icon name="far fa-chevron-right" size="16" />
          </button>
          <slick
            class="bg-white"
            ref="donationProductsSlick"
            id="donationProductWrapper"
            :options="slickDonationProductsOptions"
          >
            <div
              v-for="donationProduct in donationProductList"
              :key="donationProduct.id"
              class="item"
            >
              <div class="projeto">
                <div class="box-donation-product" :key="donationProduct.id">
                  <div>
                    <img :src="donationProduct.file ? donationProduct.file.path : ''" alt />
                    <h2 class="color-theme-primaria w-100 notranslate">
                      {{ donationProduct.name }}
                    </h2>
                    <h3 class="color-theme-texto notranslate">
                      {{
                        project.projectTypeId == ProjectTypes.Ticket
                          ? donationProduct.openValue
                            ? "OUTROS VALORES"
                            : coinSelected +
                              FormatterHelper.formatMoney(
                                donationProduct.fixedValue,
                                2
                              )
                          : donationProduct.suggestionValue
                      }}
                    </h3>
                    <p
                      class="notranslate donation-product-description"
                      v-html="donationProduct.description"
                    ></p>
                  </div>
                  <div>
                    <button
                      v-if="showDonationButton"
                      type="button"
                      :class="
                        alreadyExistsInCart({
                          project: project,
                          donationProduct: donationProduct,
                        })
                          ? 'with-check'
                          : ''
                      "
                      value="FormatterHelper.formatMoney(donationProduct.fixedValue, 2) : donationProduct.suggestionValue}}"
                      class="bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                      @click="
                        openConfirmDonationToDonationProductModal(
                          donationProduct
                        );
                        $gtag(
                          'send',
                          'event',
                          'projetos carrossel',
                          'Quero doar',
                          donationProduct.suggestionValue
                        );
                      "
                      :disabled="soldOut(donationProduct)"
                    >
                      <Icon
                        name="fas fa-check"
                        class="check-circle"
                        size="18"
                        colorFont="#02ACB4"
                      ></Icon>
                      {{
                        soldOut(donationProduct)
                          ? "Esgotados"
                          : campaignLandingPage
                          ? campaignLandingPage.donationButtonText
                          : "Quero doar"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </slick>
        </template>
        <template v-else v-for="donationProduct in donationProductList.filter((x) => x.active)">
          <div class="box-donation-product" :key="donationProduct.id">
            <div>
              <img :src="donationProduct.file ? donationProduct.file.path : ''" alt />
              <h2 id="donation-product-name" class="color-theme-primaria">
                {{ donationProduct.name }}
              </h2>
              <h3 class="color-theme-texto notranslate">
                {{
                  project.projectTypeId == ProjectTypes.Ticket
                    ? coinSelected +
                      FormatterHelper.formatMoney(donationProduct.fixedValue, 2)
                    : donationProduct.suggestionValue
                }}
              </h3>
              <p class="notranslate" v-html="donationProduct.description"></p>
            </div>
            <div>
              <button
                v-if="showDonationButton"
                type="button"
                :class="
                  alreadyExistsInCart({
                    project: project,
                    donationProduct: donationProduct,
                  })
                    ? 'with-check'
                    : ''
                "
                class="bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                @click="
                  openConfirmDonationToDonationProductModal(donationProduct);
                  $gtag(
                    'send',
                    'event',
                    'Produto de doação',
                    'Produto de doação',
                    'Botão quero doar'
                  );
                "
                :disabled="alreadyExistsInCart({
                    project: project,
                    donationProduct: donationProduct,
                  })"
              >
                <Icon
                  name="fas fa-check"
                  class="check-circle"
                  size="18"
                  colorFont="#02ACB4"
                ></Icon>
                {{
                  soldOut(donationProduct)
                    ? "Esgotados"
                    : campaignLandingPage
                    ? campaignLandingPage.donationButtonText
                    : "Quero doar"
                }}
              </button>
            </div>
          </div>
        </template>
      </v-layout>
    </div>
    <!-- <AucAlert
      ref="confirmDonationComponent"
      title="Você deseja finalizar a doação ou selecionar mais projetos?"
      confirmButtonText="Finalizar"
      cancelButtonText="Continuar escolhendo"
      @confirm="gotoCart()"
      @cancel="keepChoosing()"
    />-->
  </div>
</template>
<script type="plain/text">
import CartItem from "@/scripts/models/cartItem.model";
import AucAlert from "@/components/AucAlert.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { mapGetters, mapActions } from "vuex";
import CampaignLandingPageVue from "../views/app/campaign/CampaignLandingPage.vue";
import CampaignAppreciationCampaignProductService from "@/scripts/services/campaignAppreciationCampaignProduct.service";
export default {
  components: { AucAlert },
  props: [
    "donationProductList",
    "project",
    "showDonationButton",
    "isModal",
    "campaignLandingPage",
  ],
  data() {
    return {
      campaignAppreciationCampaignProductService:
        new CampaignAppreciationCampaignProductService(),
      selectedDonationProduct: null,
      ProjectTypes: ProjectTypes,
      FormatterHelper: new FormatterHelper(),
      coinSelected: "R$ ",
      slickDonationProductsOptions: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        prevArrow: "#agradPrevArrow",
        nextArrow: "#agradNextArrow",
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 1,
              arrows: false,
              centerMode: true,
              centerPadding: '30px'
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["alreadyExistsInCart", "logged"]),
    // disableButton() {
    //   return this.campaignDonationProductAlreadyOnCart(donationProduct.id) ||
    //   this.soldOut(donationProduct)
    // }
  },
  created() {
    if (this.campaignLandingPage.campaign.allowUsdDonation)
      this.coinSelected = "US$ ";
    this.showDonationButton = true;
  },
  methods: {
    ...mapActions(["addToCart", "removeToCart", "cleanCart"]),
    verifyWindows() {
      return !navigator.platform.indexOf("Win");
    },
    openConfirmDonationToDonationProductModal(item) {
      document.getElementById("animation-proj").classList.add("active");
      setTimeout(() => {
        document.getElementById("animation-proj").classList.remove("active");
      }, 2000);

      this.addCartItem(this.project, item);
      if (this.isModal) {
        this.$emit("close");
      }
    },
    // addCartItem(campaignProduct = null) {
    //     if (this.itemToAdd !== null) {
    //       var cartItem = {
    //         project: this.itemToAdd,
    //         donationProduct: campaignProduct,
    //         campaignProduct: campaignProduct
    //       };
    //       this.addToCart(cartItem);
    //       this.end();
    //       return
    //     }
    //     this.openConfirmDonationToDonationProductModal(campaignProduct);
    // },
    addCartItem(project, donationProduct) {
      let donationProductToAdd = { ...donationProduct };
      let projectToAdd = { ...project };

      donationProductToAdd.project = {};
      projectToAdd.donationProductList = [];
      var cartItem = {
        project: projectToAdd,
        donationProduct: donationProduct,
        campaignProduct: donationProduct.campaignProduct
      };
      this.addToCart(cartItem);

      if (!this.campaignLandingPage.campaign.donateToMoreThanOneProduct) {
        if(this.logged)
          setTimeout(() => {
            this.$store.commit("REFRESH_CART")
            this.$router.push({ name: Routes.web.CartLogged });
          }, 1500);          
        else
          this.$store.commit("SHOW_LOGIN", Routes.web.CartLogged);
      }
    },
    soldOut(donationProduct) {
      return (
        this.project.projectTypeId == ProjectTypes.Ticket &&
        !donationProduct.openTickets &&
        donationProduct.ticketsAvailableForSale <= 0
      );
    },
  },
};
</script>