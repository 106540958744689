export default class CampaignDonationStep {

	constructor() {
		this.id = 0;
		this.campaignId = 0;		
		this.title = '';
		this.body = '';
		this.icon = 'fas fa-heart';
		this.showNumber = true;
	}
}