<template>
  <div>
    <div class="widget-listitem">
      <span>Escolha a forma de doação:</span>
    </div>
    <a v-if="payments.includes(this.paymentMethod.CreditCard)" @click="$emit('onSelectPayment', 'card'); $gtag('send', 'event', 'Forma de pagamento Cartao de credito (Simplificada)', 'Forma de pagamento Cartao de credito', 'Cartão de Crédito')" :class="{selected: payment == 'card'}" class="widget-listitem">
      <i class="widget-listitem-icon fal fa-credit-card"></i>
      <span><b>Cartão de crédito</b></span>
    </a>
    <a v-if="payments.includes(this.paymentMethod.Ticket)" @click="$emit('onSelectPayment', 'barcode'); $gtag('send', 'event', 'Forma de pagamento Boleto (Simplificada)', 'Forma de pagamento Boleto', 'Boleto')" :class="{selected: payment == 'barcode'}" class="widget-listitem">
      <i class="widget-listitem-icon fal fa-barcode-alt"></i>
      <span><b>Boleto bancário</b></span>
    </a>
    <a v-if="payments.includes(this.paymentMethod.AccountDebit)" @click="$emit('onSelectPayment', 'accountDebit'); $gtag('send', 'event', 'Forma de pagamento Débito em Conta (Simplificada)', 'Forma de pagamento Débito em Conta', 'Débito em Conta')" :class="{selected: payment == 'accountDebit'}" class="widget-listitem">
      <Icon
        name="fas fa-file-invoice-dollar widget-listitem-icon"
        size="16"
      />
      <span><b>Debito em Conta</b></span>
    </a>
    <a v-if="payments.includes(this.paymentMethod.Pix)" @click="$emit('onSelectPayment', 'pix'); $gtag('send', 'event', 'Forma de pagamento Pix (Simplificada)', 'Forma de pagamento Pix', 'Pix')" :class="{selected: payment == 'pix'}" class="widget-listitem">
      <i class="widget-listitem-icon">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.83025 5.30642C5.64648 5.30642 6.41419 5.61958 6.99139 6.18805L10.1233 9.27459C10.3488 9.49694 10.7171 9.49777 10.9432 9.27433L14.0637 6.1993C14.6409 5.63083 15.4086 5.31767 16.2248 5.31767H16.6007L12.6373 1.41231C11.4029 0.196059 9.40173 0.196059 8.16749 1.41231L4.21548 5.30643L4.83025 5.30642ZM16.2249 16.1824C15.4086 16.1824 14.6409 15.8693 14.0637 15.3008L10.9432 12.226C10.7241 12.0095 10.3423 12.0102 10.1233 12.226L6.99143 15.312C6.41422 15.8805 5.64651 16.1937 4.83029 16.1937H4.21533L8.16753 20.0879C9.40176 21.3041 11.403 21.3041 12.6373 20.0879L16.6007 16.1824H16.2249ZM17.4839 6.1879L19.879 8.54791C21.1133 9.76415 21.1133 11.7359 19.879 12.9522L17.4839 15.3122C17.4312 15.2914 17.3741 15.2784 17.3138 15.2784H16.2248C15.6618 15.2784 15.1107 15.0534 14.7129 14.6611L11.5925 11.5866C11.027 11.0289 10.0402 11.029 9.47408 11.5864L6.34222 14.6724C5.94439 15.0646 5.39326 15.2896 4.83027 15.2896H3.49125C3.43413 15.2896 3.38076 15.3028 3.33028 15.3215L0.925749 12.9522C-0.308583 11.736 -0.308583 9.76416 0.925749 8.54791L3.33038 6.1785C3.38076 6.1972 3.43413 6.21047 3.49125 6.21047H4.83027C5.39326 6.21047 5.94439 6.43539 6.34222 6.82775L9.47438 9.91402C9.7663 10.2015 10.1497 10.3454 10.5334 10.3454C10.9168 10.3454 11.3005 10.2015 11.5925 9.91374L14.7129 6.83899C15.1107 6.44671 15.6618 6.2217 16.2248 6.2217H17.3138C17.3741 6.2217 17.4312 6.20871 17.4839 6.1879Z"
            fill="currentColor"
          />
        </svg>
      </i>
      <span><b>PIX</b></span>
    </a>
    <!-- <div class="widget-listitem">
      <button class="widget-btn" :disabled="parseInt(value) <= 0 || !payment" @click="$emit('onContinue', 2)">Continuar</button>
    </div> -->
  </div>
</template>

<script>
import PaymentMethod from '../../../../scripts/models/enums/paymentMethod.enum';

export default {
  props: ['payments', 'payment'],
  data(){
    return{
      paymentMethod: PaymentMethod,
    }
  }
};
</script>

<style>
</style>