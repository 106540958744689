<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-title class="headline">
          <h1>{{projectName}}</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;" class="bg-theme-fundo">
          <div class="list-scroll donation-list">
            <DonationProducts
              :showDonationButton="true"
              :isModal="true"
              :project="project"
              :donationProductList="donationProductList"
              :campaignLandingPage="campaignLandingPage"
              @close="close()"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import DonationProducts from "@/components/DonationProducts.vue";

export default {
  components: {
    DonationProducts
  },
  props: ["campaignLandingPage"],
  data() {
    return {
      dialog: false,
      project: null,
      projectName: '',
      donationProductList: []
    };
  },
  methods: {
    open(project) {
      this.dialog = true;
      this.project = project;
      this.projectName = project.shortName;
      this.donationProductList = project.donationProductList;
    },
    close(){
      this.dialog = false;
    }
  }
};
</script>
