import File from "@/scripts/models/file.model";

export default class CampaignCompanyDonationReceipt {

	constructor() {
		this.socialReason = '';
        this.cnpj = '';
        this.donationValue = '';
        this.donationDate = '';
        this.file = new File();
	}
}