<template>
  <v-dialog v-model="dialog" persistent max-width="1200">
    <div class="dialog-code">
      <div class="close">
        <button @click="close">
          <i class="fal fa-times"></i>
        </button>
      </div>
      <md-card class="font-sys text-dark">
        <md-card-media>
			<v-container fluid grid-list-sm>
				<v-layout codemirror wrap align-center>
					<template>
						<v-flex xs12 md6>
							<h3 class="w-100 text-dark pa-0"><Icon :name="'fab fa-css3-alt mr-2 text-blue'" :size="24" /> CSS</h3>
							<p class="caption">Use com sabedoria e moderação...</p>
							<CodeMirror v-model="cssCode" :options="cmOptionCSS"></CodeMirror>
						</v-flex>
					</template>
					<template v-if="hasPermission([permissions.CRUDCampaignAll])">
						<v-flex xs12 md6>
							<h3 class="w-100 text-dark pa-0"><Icon :name="'fab fa-js-square mr-2 text-blue'" :size="24" /> Javascript</h3>
							<p class="caption">Os scripts são executados no carregamento da tela.</p>
							<CodeMirror v-model="jsCode" :options="cmOptionJS"></CodeMirror>
						</v-flex>
					</template>
					<v-flex xs12 text-xs-center>
						<v-btn
						flat
						round
						color="white"
						class="btn-blue-light px-5 mt-4"
						large
						@click="apply"
					>Salvar</v-btn>
					</v-flex>
				</v-layout>
		  </v-container>
        </md-card-media>
      </md-card>
    </div>
  </v-dialog>
</template>

<script type="plain/text">
// https://surmon-china.github.io/vue-codemirror/
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";

export default {
  props: {
    dialog: false,
    cssCode: {
      type: String,
      default: `body {
	margin: 0;
	padding: 3em 6em;
	font-family: tahoma, arial, sans-serif;
	color: #454645;
}`
    },
    jsCode: {
      type: String,
      default: `console.log("Hello World!");`
    }
  },
  data() {
    return {
      permissions: Permissions,
      cmOptionCSS: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/css",
        theme: "monokai",
        lineWrapping: true
      },
      cmOptionJS: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/javascript",
        theme: "monokai",
        lineWrapping: true
      }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"])
  },
  methods: {
    close() {
      //this.dialog = false;
      this.$emit("close");
    },
    apply() {
      this.$emit("apply", [this.cssCode, this.jsCode]);
    }
  }
};
</script>

<style scoped>
.CodeMirror {
  border-radius: 4px;
}
.vue-codemirror {
  width: 100%;
}
.dialog-code {
  position: relative;
  padding: 0;
  background: white;
}
.close {
  position: absolute;
  right: 20px;
  top: 16px;
  z-index: 1;
  font-size: 24px;
  color: #c3c6d5;
}
</style>