<template>
  <div class="campanha list-campaign">
    <InfoProjectCard
      ref="infoProject"
      @simulation="landingPageOptions.simulationDrawer = !landingPageOptions.simulationDrawer"
    ></InfoProjectCard>

    <!-- MODAL DE PRODUTOS DE DOAÇÃO ######################### -->
    <DonationProductsList ref="listProductsDialog" :campaignLandingPage="campaignLandingPageConfig"></DonationProductsList>

    <section class="bloco projetos bg-theme-white" data-title="Projetos" ref="projetcsBlock">
      <div class="bloco-content">
        <h1 class="title-project-list">Conheça os projetos da campanha</h1>
        <div class="filter-list-project">
          <div>
            <input
              type="text"
              placeholder="Buscar"
              v-model="inputSearch"
              v-on:keyup.enter="searchProjects()"
            />
            <button @click="searchProjects()" class="white--text">
              <Icon name="fal fa-search" size="18" />
            </button>
          </div>
          <div class="filter-modify select-list-project">
            <div class="display-flex">
              <InputSelect
                :items="sortItems"
                v-model="selectedSort"
                @input="sortProjects()"
                unselectedText="ORDENAR POR"
                :useAnotherDefaultValue="true"
                valueAttribute="id"
                textAttribute="description"
                class="right-select"
              ></InputSelect>
            </div>
          </div>
        </div>

        <div class="wrapper mt-5 father-box-replace-project bg-projeto-white">
          <v-layout row wrap w-100>
            <div class="filter-modify">
              <div class="display-flex">
                <InputSelect
                  :items="causeList"
                  valueAttribute="id"
                  textAttribute="name"
                  v-model="selectedCause"
                  @input="searchProjects()"
                  unselectedText="Todos"
                  placeholderText="Causa"
                  :useAnotherDefaultValue="true"
                  class="left-select"
                ></InputSelect>
                <InputSelect
                  :items="coverageList"
                  valueAttribute="name"
                  textAttribute="name"
                  v-model="selectedCoverage"
                  @input="searchProjects()"
                  unselectedText="Todos"
                  placeholderText="Abrangência"
                  :useAnotherDefaultValue="true"
                  class="right-select"
                ></InputSelect>
              </div>
            </div>
          </v-layout>
          <template>
            <div
              class="box-replace-project"
              v-for="(project, i) in filteredProjects"
              :key="project.id"
              v-show="i < projectsToShowWhenNotListingAll || showAllProjects"
            >
              <div class="projeto">
                <div class="main-img">
                  <a
                    href="javascript:;"
                    @click="showLocationBox(project.id)"
                    class="map-marker-project"
                    title="Abrangência do Projeto"
                    v-if="project.coverageList.length > 0"
                  >
                    <Icon name="fas fa-map-marker-alt" size="16" />
                  </a>
                  <FileImage :file="project.projectLandingPageConfig.heroFile" />
                </div>
                <div class="logo-inst" v-show="project.institution.file != null">
                  <FileImage :file="project.institution.file" />
                </div>
                <div class="project-type">
                  <button
                    @click="openInfoDialog(project.projectTypeId)"
                    class="color-theme-primaria"
                  >
                    {{ project.projectTypeId == ProjectTypes.ProjetoIncentivado ? 'Incentivado' : project.projectTypeId == ProjectTypes.ProjetoSemIncentivoFiscal ? 'Sem Incentivo' : 'Ticket' }}
                    <Icon name="fal fa-info-circle" class="ml-2" size="18" />
                  </button>
                </div>
                <div class="content">
                  <h5>{{project.institution.entity.name}}</h5>
                  <h3 :title="project.shortName">{{project.shortName}}</h3>
                  <h6 class="mb-0">
                    <span title="Causa">{{project.cause.name}}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span title="Público" v-if="project.targetAudience">{{project.targetAudience.name}}</span>
                  </h6>
                  <!-- <span class="f-size-12">{{getCoverageName(project.coverageList)}}</span> Abrangência ão é aqui-->
                  <p
                    :title="project.objective"
                  >{{commonHelper.getSubstring(project.objective, 170)}}</p>
                  <v-layout justify-space-between>
                    <v-flex shrink>
                      <small>
                        Captação total:
                        <b
                          :title="'R$ ' + formatterHelper.formatMoney(project.totalDonatedValueWithCapturedValue, 0)"
                        >R$ {{formatterHelper.formatShortNumber(project.totalDonatedValueWithCapturedValue, 1)}}</b>
                      </small>
                    </v-flex>
                    <v-flex shrink>
                      <small>
                        Objetivo:
                        <b
                          :title="'R$ ' + formatterHelper.formatMoney(project.totalValue, 0)"
                        >R${{formatterHelper.formatShortNumber(project.totalValue, 1)}}</b>
                      </small>
                    </v-flex>
                  </v-layout>
                  <div class="progress-bar">
                    <span
                      :style="{width:calculatePercentual(project.totalDonatedValueWithCapturedValue, project.totalValue)}"
                    ></span>
                  </div>
                  <!-- IF -- se R$0 não mostra -->
                  <div style="height:35px" class="mt-3">
                    <div v-if="project.totalDonatedValueOnlyForCampaign != 0">
                      <v-layout justify-space-between>
                        <v-flex shrink color-theme-destaque>
                          <small>
                            <!-- {{campaignLandingPageConfig.campaign.name}}: -->
                            Captado nesta campanha:
                            <b
                              :title="'R$ ' + formatterHelper.formatMoney(project.totalDonatedValueOnlyForCampaign, 0)"
                            >R$ {{formatterHelper.formatShortNumber(project.totalDonatedValueOnlyForCampaign, 1)}}</b>
                          </small>
                        </v-flex>
                      </v-layout>
                      <div class="progress-bar">
                        <span
                          :style="{width:calculatePercentual(project.totalDonatedValueOnlyForCampaign, project.totalValue)}"
                          class="bg-theme-destaque"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <!-- end if R$0 -->

                  <v-layout mt-4>
                    <v-flex shrink>
                      <button
                        color="white"
                        class="btn-primary ml-0 mr-3 px-4 flat round"
                        v-if="project.captureStatus && showDonationButton"
                        :disabled="checkIfProjectAlreadyExistInTheCart(project) || (project.projectTypeId == ProjectTypes.Ticket && project.donationProductList && project.donationProductList.length == 0)"
                        large
                        @click="addProjectOrOpenDonationProductModal(project)"
                      >{{campaignLandingPageConfig ? campaignLandingPageConfig.donationButtonText : 'Quero doar'}}</button>
                      <button
                        color="white"
                        class="btn-default ml-0 mr-3 px-4 flat round"
                        v-if="project.captureStatus"
                        large
                        @click="gotoProjectLP(project.id)"
                      >Saiba mais</button>
                    </v-flex>
                    <!--<v-flex shrink>
                  <v-btn flat round class="btn-white ml-0 ma-0 px-4" large>Saiba mais</v-btn>
                    </v-flex>-->
                  </v-layout>
                </div>
                <div class="footer">
                  <v-layout justify-end align-right>
                    <!--<v-flex shrink>
                  Doaram:
                  <b>2.543</b>
                </v-flex>
                <v-flex shrink>
                  Visto por:
                  <b>23.509</b>
                    </v-flex>-->
                    <v-flex shrink>
                      Beneficiários diretos
                      <span
                        class="badge"
                        title="1200"
                      >{{project.directBeneficiaries | FormatNumber}}</span>
                    </v-flex>
                    <!-- <v-flex shrink>
                  Beneficiários indiretos
                  <span class="badge">{{project.indirectBeneficiaries}}</span>
                    </v-flex>-->
                  </v-layout>
                </div>
                <div class="apoiadores">
                  <v-layout justify-end align-right>
                    <v-flex shrink>
                      <span
                        v-if="!getSelectedCompaines(project.projectLandingPageConfig, true)"
                      >Apoio: {{campaignLandingPageConfig.campaign.company.entity.name}}</span>
                      <span
                        v-else
                        :title="getSelectedCompaines(project.projectLandingPageConfig, false)"
                      >{{getSelectedCompaines(project.projectLandingPageConfig, true)}}</span>
                    </v-flex>
                  </v-layout>
                </div>
                <template>
                  <!-- :class="active" -->
                  <div
                    :ref="'locationBox'+project.id"
                    class="overlay-location-project"
                    @click="hideLocationBox(project.id)"
                  >
                    <div class="info-location-project">
                      <a
                        href="javascript:;"
                        class="close-location-project"
                        @click="hideLocationBox(project.id)"
                      >
                        <Icon name="fal fa-times" size="18" colorFont="#80849A" />
                      </a>
                      <h2>Abrangência</h2>
                      <ul>
                        <li v-for="coverage in project.coverageList" :key="coverage.id">
                          <Icon name="fal fa-map-marker-alt" size="20" class="mr-3" />
                          {{coverage.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div
          class="button-show-more-projects py-3"
          v-if="!showAllProjects && filteredProjects!=null && filteredProjects.length > projectsToShowWhenNotListingAll"
        >
          <button class="btn-ghost-primary v-btn--large" @click="showAllProjects = true">
            <span>Mostrar mais</span>
            <span class="badge">{{filteredProjects.length - projectsToShowWhenNotListingAll}}</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script type="plain/text">
import BorderSeparation from "@/components/BorderSeparation.vue";
import FileProjectImage from "@/components/FileProjectImage.vue";
import DonationProductsList from "@/components/DonationProductsList.vue";
import ProjectGalleryView from "@/components/ProjectGalleryView.vue";
import ProjectContactLP from "@/components/ProjectContactLP.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import FileProject from "@/scripts/models/fileProject.model";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import FileService from "@/scripts/services/file.service";
import FileProjectService from "@/scripts/services/fileProject.service";
import CampaignService from "@/scripts/services/campaign.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import FileImage from "@/components/FileImage.vue";
import InfoProjectCard from "@/components/InfoProjectCard.vue";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum.js";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import { Causes } from "@/scripts/models/enums/causes.enum";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BorderSeparation,
    FileProjectImage,
    DonationProductsList,
    ProjectGalleryView,
    ProjectContactLP,
    FileImage,
    InfoProjectCard
  },
  props: ["projectId", "landingPageOptions"],
  data() {
    return {
      projectsToShowWhenNotListingAll: 6,
      showAllProjects: false,
      tab: null,
      edit: false,
      items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      fileType: FileType,
      filteredProjects: [],
      causeList: [],
      coverageList: [],
      sortItems: [
        { id: "project", description: "Projeto", desc: false },
        { id: "institution", description: "Instituição", desc: false },
        {
          id: "objective",
          description: "Objetivo do Projeto (R$)",
          desc: true
        },
        {
          id: "capturedMax",
          description: "Maior Valor Arrecadado (R$)",
          desc: true
        },
        {
          id: "capturedMin",
          description: "Menor Valor Arrecadado (R$)",
          desc: false
        }
      ],
      ageRangeList: [],
      commonHelper: CommonHelper,
      project: new Project(),
      projectLP: new ProjectLandingPageConfig(),
      projectService: new ProjectService(),
      fileProject: new FileProject(),
      fileProjectService: new FileProjectService(),
      fileService: new FileService(),
      formatterHelper: new FormatterHelper(),
      campaignService: new CampaignService(),
      campaignLandingPageConfig: new CampaignLandingPageConfig(),
      selectedFileProjectId: null,
      project: {
        type: "Galeria de Fotos"
      },
      targetAudienceList: [],
      getProjectTypeName: GetProjectTypeName,
      ProjectTypes: ProjectTypes,
      projectPhasesText: ProjectPhasesText,
      selectedCause: null,
      selectedCoverage: null,
      selectedSort: "",
      inputSearch: "",
      sortDescending: false,
      showDonationButton: false
    };
  },
  beforeRouteEnter: (to, from, next) => {
    let campaignService = new CampaignService();
    campaignService
      .getCampaignLandingPageConfigByCampaignIdWithoutCarts(to.query.campaignId)
      .then(function(data) {
        to.query.campaignLandingPageConfig = data;
        to.query.causeId = to.query.causeId;
        to.query.coverage = to.query.coverage;
        next();
      });
  },
  created() {
    this.campaignLandingPageConfig = this.$route.query.campaignLandingPageConfig;

    if (this.$route.query.causeId)
      this.selectedCause = parseInt(this.$route.query.causeId);
    if (this.$route.query.coverage)
      this.selectedCoverage = this.$route.query.coverage;

    if (
      this.campaignLandingPageConfig &&
      this.campaignLandingPageConfig.campaign &&
      this.campaignLandingPageConfig.campaign.file
    ) {
      this.showDonationButton =
        CommonHelper.getSubdomain() != null &&
        this.campaignLandingPageConfig.campaign.campaignPhaseId ==
          CampaignPhases.EmAndamento &&
        this.campaignLandingPageConfig.campaign.published;

      this.$set(
        this.landingPageOptions,
        "logo",
        this.campaignLandingPageConfig.campaign.file
      );
    }

    this.campaignService
      .getCampaignProjectList(this.campaignLandingPageConfig.campaign.id)
      .then(this.campaignService_getCampaignProjectListCallback);
    // this.fillCausesAndCoveragesList(this.campaignLandingPageConfig.projectList);
    // this.searchProjects();
  },
  computed: {
    ...mapGetters(["alreadyExistsInCart"])
  },
  methods: {
    ...mapActions(["addToCart"]),
    showLocationBox(projectId) {
      var ref = this.$refs["locationBox" + projectId];
      if (ref) ref[0].classList.add("active");
    },
    openInfoDialog(projectType) {
      this.$refs.infoProject.open(projectType);
    },
    hideLocationBox(projectId) {
      var ref = this.$refs["locationBox" + projectId];
      if (ref) ref[0].classList.remove("active");
    },
    showLocationBox(projectId) {
      var ref = this.$refs["locationBox" + projectId];
      if (ref) ref[0].classList.add("active");
    },
    campaignService_getCampaignProjectListCallback(data) {
      let campaignCause = data.filter(
        el => el.cause.id == Causes.DoacaoParaCampanha
      );
      campaignCause.forEach(el => {
        let index = data.indexOf(el);
        data.splice(index, 1);
      });
      this.campaignLandingPageConfig.projectList = CommonHelper.shuffleArray(
        data
      );
      campaignCause.forEach(el => {
        this.campaignLandingPageConfig.projectList.unshift(el);
      });
      this.searchProjects();
    },
    campaignService_getProjectCauseListCallback(data) {
      this.causeList = data;
    },
    campaignService_getProjectCoverageListCallback(data) {
      this.coverageList = data;
    },
    // fillCausesAndCoveragesList(projectList) {
    //   this.causeList = projectList.map(project => project.cause);
    //   this.causeList = Array.from(
    //     new Set(this.causeList.map(cause => cause.id))
    //   ).map(causeId => this.causeList.find(cause => cause.id == causeId));

    //   this.coverageList = projectList
    //     .map(project => project.coverageList)
    //     .flat();
    //   this.coverageList = Array.from(
    //     new Set(this.coverageList.map(coverage => coverage.name))
    //   ).map(coverageName =>
    //     this.coverageList.find(coverage => coverage.name == coverageName)
    //   );

    //   this.coverageList.sort((a, b) => {
    //     if (a.name > b.name) return 1;
    //     else if (a.name < b.name) return -1;
    //     return 0;
    //   });
    //   this.causeList.sort((a, b) => {
    //     if (a.name > b.name) return 1;
    //     else if (a.name < b.name) return -1;
    //     return 0;
    //   });
    // },
    addProjectOrOpenDonationProductModal(item) {
      if (item.donationProductList.length == 0) {
        this.addCartItem(item);
        document.getElementById("animation-proj").classList.add("active");
        setTimeout(() => {
          document.getElementById("animation-proj").classList.remove("active");
        }, 2000);
      } else {
        if (Array.isArray(this.$refs.listProductsDialog))
          this.$refs.listProductsDialog[0].open(item);
        else this.$refs.listProductsDialog.open(item);
      }
    },
    addCartItem(project) {
      var cartItem = {
        project: project
      };
      this.addToCart(cartItem);
    },
    checkIfProjectAlreadyExistInTheCart(project) {
      if (project && project.donationProductList.length == 0)
        return this.alreadyExistsInCart({ project: project });
      return false;
    },
    gotoCart() {
      this.$router.push({ name: Routes.web.CartLogged });
    },
    gotoProjectLP(projectId) {
      this.$router.push({
        name: Routes.web.ProjectLP,
        query: { projectId: projectId }
      });
    },
    getSelectedCompaines(projectLandingPageConfig, onlyTwo) {
      var result = "";
      if (projectLandingPageConfig && projectLandingPageConfig.project) {
        var campaignProjectList =
          projectLandingPageConfig.project.campaignProjectList;
        if (campaignProjectList && campaignProjectList.length > 0) {
          for (let i = 0; i < campaignProjectList.length; i++) {
            var campaignProjectCompanyList =
              campaignProjectList[i].campaignProjectCompanyList;
            if (
              campaignProjectCompanyList &&
              campaignProjectCompanyList.length > 0
            ) {
              for (let j = 0; j < campaignProjectCompanyList.length; j++) {
                if (
                  campaignProjectCompanyList[j].company &&
                  campaignProjectCompanyList[j].company.entity
                ) {
                  if (result == "") {
                    result =
                      "Apoio: " +
                      campaignProjectCompanyList[j].company.entity.name;
                  } else if (onlyTwo) {
                    if (j < 2)
                      result +=
                        ", " +
                        campaignProjectCompanyList[j].company.entity.name;
                    else {
                      result += ", ...";
                      j = campaignProjectCompanyList.length;
                    }
                  } else {
                    result +=
                      ", " + campaignProjectCompanyList[j].company.entity.name;
                  }
                }
              }
            }
          }
        }
      }
      return result;
    },
    searchProjects() {
      this.filteredProjects = this.campaignLandingPageConfig.projectList.filter(
        project => {
          let apoiadores =
            this.campaignLandingPageConfig.campaign.company.entity.name +
            this.getSelectedCompaines(project.projectLandingPageConfig, false);
          let textSearch =
            project.institution.entity.name +
            project.shortName +
            apoiadores.replace("Apoio: ", "");
          return (
            (!this.selectedCoverage ||
              project.coverageList
                .map(coverage => coverage.name)
                .includes(this.selectedCoverage)) &&
            (!this.selectedCause ||
              project.cause.id == parseInt(this.selectedCause)) &&
            (this.inputSearch == "" ||
              textSearch.toLowerCase().includes(this.inputSearch.toLowerCase()))
          );
        }
      );
      let campaignCause = this.filteredProjects.filter(
        el => el.cause.id == Causes.DoacaoParaCampanha
      );
      campaignCause.forEach(el => {
        let index = this.filteredProjects.indexOf(el);
        this.filteredProjects.splice(index, 1);
      });
      campaignCause.forEach(el => {
        this.filteredProjects.unshift(el);
      });

      // this.fillCausesAndCoveragesList(this.filteredProjects);
      if (this.selectedCause == "") this.selectedCause = null;
      if (this.selectedCoverage == "") this.selectedCoverage = null;
      this.campaignService
        .getProjectCauseList(
          this.campaignLandingPageConfig.campaign.id,
          this.selectedCoverage
        )
        .then(this.campaignService_getProjectCauseListCallback);

      this.allowCoverageSearch = false;
      this.campaignService
        .getProjectCoverageList(
          this.campaignLandingPageConfig.campaign.id,
          this.selectedCause
        )
        .then(this.campaignService_getProjectCoverageListCallback);
    },
    calculatePercentual(current, total, minimum = 0, maximum = 100) {
      let v = 0;
      if (total > 0) {
        v = Math.round((current / total) * 100);
        if (v < 100) v = Math.min(maximum, Math.max(minimum, v));
        else if (v > 100) v = 100;
      }
      return v + "%";
    },
    sortProjects() {
      if (this.selectedSort == "project") {
        this.filteredProjects.sort((a, b) => {
          if (a.fullName > b.fullName) return this.sortDescending ? -1 : 1;
          else if (a.fullName < b.fullName) return this.sortDescending ? 1 : -1;
          return 0;
        });
      }

      if (this.selectedSort == "institution") {
        this.filteredProjects.sort((a, b) => {
          if (a.institution.entity.name > b.institution.entity.name)
            return this.sortDescending ? -1 : 1;
          else if (a.institution.entity.name < b.institution.entity.name)
            return this.sortDescending ? 1 : -1;
          return 0;
        });
      }

      if (
        this.selectedSort == "capturedMax" ||
        this.selectedSort == "capturedMin"
      ) {
        this.sortDescending = this.selectedSort == "capturedMax" ? false : true;
        this.filteredProjects.sort((a, b) => {
          if (
            a.totalDonatedValueWithCapturedValue >
            b.totalDonatedValueWithCapturedValue
          )
            return this.sortDescending ? 1 : -1;
          else if (
            a.totalDonatedValueWithCapturedValue <
            b.totalDonatedValueWithCapturedValue
          )
            return this.sortDescending ? -1 : 1;
          return 0;
        });
      }

      if (this.selectedSort == "objective") {
        this.filteredProjects.sort((a, b) => {
          if (a.totalValue > b.totalValue) return this.sortDescending ? 1 : -1;
          else if (a.totalValue < b.totalValue)
            return this.sortDescending ? -1 : 1;
          return 0;
        });
      }
    }
  }
};
</script>