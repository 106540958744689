export default class GoalsValuesFile {

    constructor() {
        this.id = 0;
        this.fileId = 0;
        this.goalsValuesId = 0;
        this.videoId = 0;
        this.file = {};
        this.video = {};
    }
}